import { Component, Input, OnInit } from "@angular/core";

interface Guideline {
	appName: string;
	link: string;
}

@Component({
	selector: "app-guideline-button",
	templateUrl: "./guideline-button.component.html",
	styleUrls: ["./guideline-button.component.scss"],
})
export class GuidelineButtonComponent implements OnInit {
	@Input() appName!: string;

	guidelines = [
		{
			appName: "plans-overall",
			link: "https://www.yumealz.com/wp-content/uploads/2023/08/PLAN-BUILDING-GUIDE-En-1.pdf",
		},
		{
			appName: "subscriptions-overall",
			link: "https://www.yumealz.com/wp-content/uploads/2023/08/Subscription-management-guide.pdf",
		},
		{
			appName: "complaints-overall",
			link: "https://www.yumealz.com/wp-content/uploads/2023/08/Customer-Complaints-Guide.pdf",
		},
		{
			appName: "vouchers-overall",
			link: "https://www.yumealz.com/wp-content/uploads/2023/08/Voucher-Creation.pdf",
		},
	];
	constructor() {}

	ngOnInit(): void {}
	getAppLink() {
		const app = this.guidelines.find((app) => app.appName === this.appName);
		if (app) {
			return app.link;
		} else {
			// todo add request to get the guideline link
			return "https://www.yumealz.com";
		}
	}
}
