<div class="my-dialog-form-container">
	<mat-icon (click)="closeDialog()" class="close-button">close</mat-icon>

	<div class="message">
		{{ data.message | translate }}
	</div>

	<a *ngIf="data.routeLink && data.routeMessage" (click)="navigate()">
		{{ data.routeMessage | translate }}</a
	>
	<div class="actions">
		<button
			class="button"
			color="accent"
			mat-raised-button
			(click)="closeDialog()"
		>
			{{ data.okayMessage | translate }}
		</button>
	</div>
</div>
