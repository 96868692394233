import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router, RouterLink } from "@angular/router";

export interface IAlertPopUpData {
	message: string;
	okayMessage: string;
	routeLink?: string[];
	routeMessage?: string;
}

@Component({
	selector: "app-alertpopup",
	templateUrl: "./alertpopup.component.html",
	styleUrls: ["./alertpopup.component.scss"],
})
export class AlertPopupComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<AlertPopupComponent>,
		@Inject(MAT_DIALOG_DATA) public data: IAlertPopUpData,
		private router: Router
	) {}

	ngOnInit(): void {
		this.setDefaultValues();
	}

	setDefaultValues() {
		if (!this.data.okayMessage) {
			this.data.okayMessage = "okay";
		}
	}
	closeDialog() {
		this.dialogRef.close(false);
	}
	navigate() {
		if (this.data.routeLink) {
			this.router.navigate(this.data.routeLink);
			this.closeDialog();
		}
	}
}
