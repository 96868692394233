import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface ISelectAppIdDialogData {
	modelName: string;
	supportMultiSelect: boolean;
	headerTexts?: string[];
}

@Component({
	selector: "app-select-app-id",
	templateUrl: "./select-app-id.component.html",
	styleUrls: ["./select-app-id.component.scss"],
})
export class SelectAppIdComponent implements OnInit {
	singleSelectId: number | undefined;
	multipleSelectIds: number[] = [];

	constructor(
		public dialogRef: MatDialogRef<SelectAppIdComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ISelectAppIdDialogData,
		private fb: FormBuilder
	) {}

	ngOnInit() {
		this.setDefaultValueForHeaderTexts();
	}
	setDefaultValueForHeaderTexts() {
		if (!this.data.headerTexts?.length) {
			this.data.headerTexts = ["choose-app", "to-add-the"];
		}
	}
	onMultipleSelectChange(ids: number[]) {
		this.multipleSelectIds = ids;
	}
	onSingleSelectChange(id: number) {
		this.singleSelectId = id;
	}
	confirm() {
		if (this.data.supportMultiSelect) {
			this.dialogRef.close(this.multipleSelectIds);
		} else {
			this.dialogRef.close([this.singleSelectId]);
		}
	}
	closeForm() {
		this.dialogRef.close();
	}
}
