import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SidebarComponent } from "./sidebar/sidebar.component";

import { RouterModule } from "@angular/router";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ReusableComponentsModule } from "../../reusable-components/reusable-components.module";
import { UnderMaintenancePageModule } from "../../reusable-components/modules/under-maintenance-page/under-maintenance-page.module";

@NgModule({
	declarations: [SidebarComponent],
	imports: [
		CommonModule,
		ReusableComponentsModule,
		RouterModule,
		MatTooltipModule,
		UnderMaintenancePageModule,
	],
	exports: [SidebarComponent],
})
export class SidebarModule {}
