import { Directionality } from "@angular/cdk/bidi";
import { Injectable } from "@angular/core";
import * as en from "../../translations/en.json";
import * as ar from "../../translations/ar.json";
import * as gym_en from "../../translations/gym_en.json";
import * as gym_ar from "../../translations/gym_ar.json";
import { getDecodedToken } from "../../../../shared/general-utils";
import { YumealzApiServiceV2 } from "src/app/shared/services/yumealz-api-service-v2/yumealz-api-v2.service";
import { IDashboardUserProfile } from "src/app/shared/models/classes.model";

@Injectable({
	providedIn: "root",
})
export class LanguageService {
	lang: Lang = Lang.english;
	private dashboardUserProfile: IDashboardUserProfile | undefined;
	isLoadingDashboardProfile = false;

	constructor(
		private dir: Directionality,
		private yumealzApiServiceV2: YumealzApiServiceV2
	) {
		const langFromLocalStorage = localStorage.getItem("lang") as Lang;
		this.lang = langFromLocalStorage ?? Lang.english;
		this.adjustHtmlDirection();
	}
	translate(term: string): string {
		const type = this.getCurrentOrgType();

		let localizedTerm;
		if (type === "gym") {
			switch (this.lang) {
				case Lang.english:
					localizedTerm = gym_en[term as keyof typeof gym_en];
					break;

				case Lang.arabic:
					localizedTerm = gym_ar[term as keyof typeof gym_ar];
					break;
			}
			if (typeof localizedTerm !== "undefined") {
				return localizedTerm;
			}
		}

		switch (this.lang) {
			case Lang.english:
				localizedTerm = en[term as keyof typeof en];
				break;
			case Lang.arabic:
				localizedTerm = ar[term as keyof typeof ar];
				break;
		}
		if (typeof localizedTerm === "undefined") {
			localizedTerm = term;
		}

		return localizedTerm;
	}

	getAvailableLangs(): Lang[] {
		return [Lang.arabic, Lang.english];
	}

	getTheOther(): Lang {
		return this.lang === Lang.english ? Lang.arabic : Lang.english;
	}
	getCurrentLang(): Lang {
		return this.lang;
	}
	setLang(lang: Lang): void {
		this.lang = lang;
		// this.authService.languageCode = Promise.resolve(lang);
		localStorage.setItem("lang", this.lang);
		this.adjustHtmlDirection();
	}

	switchLang(): void {
		if (this.lang === Lang.english) {
			this.lang = Lang.arabic;
		} else {
			this.lang = Lang.english;
		}

		localStorage.setItem("lang", this.lang);
		this.adjustHtmlDirection();
	}

	private getCurrentOrgType(): string | undefined {
		const token = getDecodedToken();
		return token?.obtype;
	}
	private adjustHtmlDirection(): void {
		document.getElementsByTagName("html")[0].dir = this.getLangDirection();
		document.getElementsByTagName("html")[0].lang = this.lang;
		document
			.getElementsByTagName("html")[0]
			.classList.remove(...["rtl", "ltr"]);
		document
			.getElementsByTagName("html")[0]
			.classList.add(this.getLangDirection());

		document.body.setAttribute("dir", this.getLangDirection());
		// Notify Angular Material components of the change
		(this.dir as any).value = this.getLangDirection();
	}
	private getLangDirection(langValue?: string): "ltr" | "rtl" {
		const l = langValue ?? this.lang;
		switch (l) {
			case Lang.english:
				return "ltr";
			case Lang.arabic:
				return "rtl";
		}
		return "ltr";
	}
}

export enum Lang {
	arabic = "ar",
	english = "en",
}
