import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpApiInterceptorService } from './http-api-interceptor.service';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpApiInterceptorService,
    multi: true,
  },
];
