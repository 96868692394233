import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, fromEvent, merge } from "rxjs";
import { map, mapTo } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class ConnectivityService {
	private onlineStatus$: BehaviorSubject<boolean> = new BehaviorSubject(
		navigator.onLine
	);

	constructor() {
		const online$ = fromEvent(window, "online").pipe(map(() => true));
		const offline$ = fromEvent(window, "offline").pipe(map(() => false));

		merge(online$, offline$).subscribe((status) => {
			this.onlineStatus$.next(status);
		});
	}

	get isOnline(): Observable<boolean> {
		return this.onlineStatus$.asObservable();
	}
}
