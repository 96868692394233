<div
	class="badge"
	[class.active-badge]="value"
	[class.not-active-badge]="!value"
	[matTooltip]="toolTipMessage"
	[style.width]="widthWithUnits"
	[style.height]="heightWithUnits"
>
	{{ (value ? textValue : textWhenInactive) | translate }}
</div>
