import { Injectable } from "@angular/core";

import jwt_decode from "jwt-decode";
import { User } from "../../models/classes.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class JwtService {
	private readonly sessionStorageAccessTokenKey: string = "accessToken";
	private readonly sessionStorageRefreshTokenKey: string = "refreshToken";
	private readonly serverResAccessTokenKey: string = "access";
	private readonly serverResRefreshTokenKey: string = "refresh";

	private readonly allowedExpirationTimeDifference = 0;

	private refreshTokenEndPoint =
		environment.newApiUrl + "api/v2/auth/dashboard-refresh/";

	refreshTokenSubscription: Subscription | undefined;

	constructor(private http: HttpClient) {}

	storeTokens(response: { [x: string]: any }): User {
		const jwtAccessToken = response[this.serverResAccessTokenKey];
		const jwtRefreshToken = response[this.serverResRefreshTokenKey];
		localStorage.setItem(this.sessionStorageAccessTokenKey, jwtAccessToken);
		localStorage.setItem(this.sessionStorageRefreshTokenKey, jwtRefreshToken);
		return this.extractUserFromToken();
	}

	storeAccessToken(response: { [x: string]: any }) {
		const jwtAccessToken = response[this.serverResAccessTokenKey];
		localStorage.setItem(this.sessionStorageAccessTokenKey, jwtAccessToken);
	}
	clearTokens(): void {
		localStorage.removeItem(this.sessionStorageRefreshTokenKey);
		localStorage.removeItem(this.sessionStorageAccessTokenKey);
	}

	getRefreshToken(): string | null {
		return localStorage.getItem(this.sessionStorageRefreshTokenKey);
	}

	getAccessToken(): string | undefined {
		return localStorage.getItem(this.sessionStorageAccessTokenKey) || undefined;
	}

	getDecodedAccessToken(): any {
		const accessToken = this.getAccessToken();
		if (accessToken == undefined) {
			// todo handle this

			return undefined;
		} else {
			const decodedToken = jwt_decode(accessToken);
			return decodedToken;
		}
	}
	isAccessTokenValid(): boolean {
		const token = this.getAccessToken();

		// TODO remove this later
		const decodedToken = this.getDecodedAccessToken();

		if (!decodedToken) {
			return false;
		}

		const doesTokenHaveOrgId = decodedToken.hasOwnProperty("org_id");

		if (!token || !doesTokenHaveOrgId) {
			return false;
		}

		const expirationInMilliSeconds = this.extractAccessTokenExpiration();

		const nowInMilliSeconds = Date.now();
		const isTokenExpired =
			nowInMilliSeconds - expirationInMilliSeconds >
			this.allowedExpirationTimeDifference;

		if (isTokenExpired) {
			this.refreshToken();
		}

		return !isTokenExpired;
	}

	refreshToken(): void {
		const refreshToken = this.getRefreshToken();
		if (!refreshToken) {
			this.clearTokens();
			return;
		}
		this.refreshTokenSubscription = this.http
			.post(`${this.refreshTokenEndPoint}`, { refresh: refreshToken })
			.subscribe((response) => {
				this.storeAccessToken(response);
				window.location.reload();
				this.unsubscribeFromRefreshToken();
			});
	}

	unsubscribeFromRefreshToken(): void {
		if (this.refreshTokenSubscription)
			this.refreshTokenSubscription.unsubscribe();
	}

	isRefreshTokenValid(): boolean {
		const refreshToken = this.getRefreshToken();
		if (!refreshToken) {
			return false;
		}
		const decodedToken: any = jwt_decode(refreshToken);

		if (!decodedToken) return false;

		const expDateInMilliSeconds = decodedToken["exp"] * 1000;
		const nowInMilliSeconds = Date.now();
		const isTokenExpired =
			nowInMilliSeconds - expDateInMilliSeconds >
			this.allowedExpirationTimeDifference;

		return !isTokenExpired;
	}
	private extractUserFromToken(): User {
		// todo fix this
		const jwtToken = this.getRefreshToken();
		if (!jwtToken) {
			throw new Error("jwt token is undefined");
			return {
				username: "dummy user",
			};
		}

		const decodedToken = jwt_decode(jwtToken);

		// todo fix this
		const user = {
			username: "dummy user",
		};
		// const user: User = {
		//   username: decodedToken['username'],
		// };
		return user;
	}

	private extractAccessTokenExpiration(): number {
		const accessToken = this.getAccessToken();
		if (accessToken == undefined) {
			this.clearTokens();
			return 0;
		}
		const decodedToken: any = jwt_decode(accessToken);

		// const expDateInMilliSeconds = Date.now() + 100000;
		const expDateInMilliSeconds = decodedToken["exp"] * 1000;
		return expDateInMilliSeconds;
	}
}
