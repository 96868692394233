import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GoogleMapsModule } from "@angular/google-maps";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";

const modules = [
	CommonModule,
	HttpClientJsonpModule,
	HttpClientModule,
	GoogleMapsModule,
];

@NgModule({
	imports: [...modules],
	exports: [...modules],
})
export class MyGoogleMapModule {}
