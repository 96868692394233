import jwt_decode from "jwt-decode";

export function getDecodedToken(): any {
	let sessionStorageAccessTokenKey = "accessToken";
	const accessToken =
		localStorage.getItem(sessionStorageAccessTokenKey) || undefined;
	if (accessToken) {
		const decodedToken = jwt_decode(accessToken);
		return decodedToken;
	}
}
