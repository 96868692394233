import { MatSnackBar } from "@angular/material/snack-bar";
import { YumealzApiService } from "../yumealz-api-service/yumealz-api.service";
import * as distinct_access from "./distinct_access.json";
import * as elements_hide_dates from "./elements_hide_dates.json";
import * as business_type_hidden_elements from "./business_type_hidden_elements.json";
import { environment } from "src/environments/environment";
import {
	filter,
	firstValueFrom,
	map,
	Observable,
	of,
	shareReplay,
	Subject,
	Subscription,
	take,
	tap,
} from "rxjs";
import { Injectable, OnDestroy } from "@angular/core";
import {
	ICity,
	IDashboardUserProfile,
	IDeliveryWindow,
	Restaurant,
	User,
} from "src/app/shared/models/classes.model";
import { AuthService, IUserAccessType } from "../auth-service/auth.service";
import { LanguageService } from "src/app/translate/translation/services/language-service/language.service";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmActionDialogComponent } from "../../reusable-components/components/confirm-action-dialog/confirm-action-dialog.component";
import { SelectAppIdComponent } from "../../reusable-components/modules/select-app-id/select-app-id/select-app-id.component";
import { AlertPopupComponent } from "../../reusable-components/components/alertpopup/alertpopup.component";
import { getDecodedToken } from "../../general-utils";

import { HttpClient } from '@angular/common/http';

export enum OrderStatus {
	"confirmed" = "confirmed",
	"delivered" = "delivered",
	// todo add the rest of the statuses
}

export enum SubOrderStatus {
	"confirmed" = "confirmed",
	"delivered" = "delivered",
	"Cancelled_by_operation" = "cancelled_by_operation",
	"cancelled_by_customer" = "cancelled_by_customer",
	"not_received_from_restaurant" = "not_received_from_restaurant",
	"not_received_by_customer" = "not_received_by_customer",
	"all" = "",
}

export enum SubscriptionStatus {
	"confirmed" = "confirmed",
	"finished" = "finished",
	"confirmed and finished" = "confirmed,finished",
	"cancelled_by_operation" = "cancelled_by_operation",
	"cancelled_by_customer" = "cancelled_by_customer",
	"all" = "all",

	// todo add the rest of the statuses
}

export enum ActivityStatus {
	"active" = 1,
	"inactive" = 0,
	"all" = -1,
}

@Injectable({
	providedIn: "root",
})
export class AppService implements OnDestroy {
	isLoggedIn = this.authService.isLoggedIn();
	isHandset$: Observable<boolean> = this.breakpointObserver
		.observe([Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
		.pipe(
			map((result) => {
				return result.matches;
			}),
			shareReplay()
		);

	private dashboardUserProfile!: IDashboardUserProfile;
	private dashboardUSerProfileSubject: Subject<
		IDashboardUserProfile | undefined
	> = new Subject();
	private restaurants: Restaurant[] | undefined;
	cities: ICity[] = [];

	private subscriptions: Subscription[] = [];

	isUnderMaintenance: boolean = false
	workerApi= environment.Worker.workerApi;

	constructor(
		private authService: AuthService,
		private yumealzApiService: YumealzApiService,
		private languageService: LanguageService,
		private matSnackBar: MatSnackBar,
		private breakpointObserver: BreakpointObserver,
		private dialog: MatDialog,
		public http: HttpClient
	) {
		// this.setDashboardUserProfile();
	}

	private setDashboardUserProfile() {
		if (!this.isLoggedIn) return;
		this.yumealzApiService
			.getDashboardUserProfile()
			.pipe(take(1))
			.subscribe((dashboardUserProfile) => {
				this.dashboardUserProfile = dashboardUserProfile;
				this.dashboardUSerProfileSubject.next(this.dashboardUserProfile);
			});
	}

	getDashboardUserProfile(): Observable<IDashboardUserProfile | undefined> {
		if (!this.isLoggedIn) return of(undefined);

		if (this.isUnderMaintenance === true) {
			return of(undefined);
		}
		if (this.dashboardUserProfile) {
			return of(this.dashboardUserProfile);
		} else {
			return this.dashboardUSerProfileSubject.pipe(
				filter((profile) => !!profile),
				take(1)
			);
		}
	}
	isProduction() {
		return environment.production;
	}

	getUserName() {
		return this.dashboardUserProfile?.name;
	}
	alertPopUp(message: string, routeLink?: string[], routeMessage?: string) {
		const panelClass =
			this.getCurrentLanguage() === "ar" ? "dialog-rtl" : "dialog-ltr";
		const translatedMessage = this.translate(message);
		this.dialog.open(AlertPopupComponent, {
			data: {
				message: translatedMessage,
				routeLink,
				routeMessage,
			},
			width: "400px",
			panelClass,
		});
	}

	getListOfCities(): Observable<ICity[]> {
		if (this.cities.length) return of(this.cities);
		else
			return this.yumealzApiService
				.getCities()
				.pipe(map((v) => v.results))
				.pipe(
					tap((v) => {
						this.cities = v;
					})
				);
	}
	getListOfRestaurants(): Observable<Restaurant[]> {
		if (this.restaurants) return of(this.restaurants);
		else return this.yumealzApiService.getListOfRestaurants();
	}

	async getListOfRestaurantsPromise(): Promise<Restaurant[]> {
		if (this.isUnderMaintenance === true) {
			return Promise.reject('System is under maintenance');
		}
		else if (this.restaurants) {
			return Promise.resolve(this.restaurants);
		} else {
			// Convert the Observable to a Promise
			return firstValueFrom(this.yumealzApiService.getListOfRestaurants());
		}
	}

	notifySuccess(message: string, duration = 3000) {
		const okText = this.getCurrentLanguage() == "en" ? "Ok" : "حسنا";
		message = "✅ " + message;
		this.matSnackBar.open(message, okText, {
			duration: duration,
			// panelClass: ["multiline-snackbar"],
		});
	}
	public handleGlobalClick(event: MouseEvent): void {
		this.matSnackBar.dismiss();
	}
	notifyFailure(message: string, duration = 3000) {
		const okText = this.getCurrentLanguage() == "en" ? "Ok" : "حسنا";

		message = "🚨 " + message;
		this.matSnackBar.open(message, okText, {
			duration: duration,
		});
	}
	notifyAlert(message: string, duration = 3000) {
		const okText = this.getCurrentLanguage() == "en" ? "Ok" : "حسنا";
		message = "❗️ " + message;
		this.matSnackBar.open(message, okText, {
			duration: duration,
		});
	}

	notifyLoading(message: string, duration = 3000) {
		const okText = this.getCurrentLanguage() == "en" ? "Ok" : "حسنا";

		message = "⏳ " + message;
		this.matSnackBar.open(message, okText, {
			duration: duration,
		});
	}
	isYumealzUser(): boolean {
		return this.authService.isYumealzUser();
	}
	updatePageTitle(title: string) {
		document.title = title;
	}
	isMobileDevice(): boolean {
		const userAgent = navigator.userAgent;

		// A simple check against some common mobile device indicators
		return /iPhone|iPad|iPod|Android/i.test(userAgent);
	}
	canView(elementName: string): boolean {
		const userPermissions = this.dashboardUserProfile?.permissions
			? this.dashboardUserProfile.permissions
			: [];

		const requestedPermissionsToViewElement =
			distinct_access[elementName as keyof typeof distinct_access];

		return requestedPermissionsToViewElement?.some((permission) =>
			userPermissions.includes(permission)
		);
	}
	shouldHideElement(elementName: string) {
		const orgType = this.getCurrentOrgType();
		const orgsTypesToHideElementIn: string[] =
			business_type_hidden_elements[
				elementName as keyof typeof business_type_hidden_elements
			];

		if (!orgType || orgsTypesToHideElementIn?.length === 0) {
			return false;
		}
		if (orgsTypesToHideElementIn?.includes(orgType)) {
			return true;
		}
		return false;
	}
	getCurrentOrgType(): "gym" | "restaurant" | "both" | undefined {
		const token = getDecodedToken();
		return token?.obtype;
	}

	getPermissionsNames(elementName: string): string[] {
		return distinct_access[elementName as keyof typeof distinct_access];
	}

	shouldShowElement(elementName: string) {
		let elementHideDateString =
			elements_hide_dates[elementName as keyof typeof elements_hide_dates];

		if (!elementHideDateString) {
			elementHideDateString = this.getDateAsStringFromDate(new Date());
		}

		const today = new Date();
		const elementHideDate = new Date(elementHideDateString);

		return today < elementHideDate;
	}

	getAccessType(): IUserAccessType {
		return this.authService.getUserAccessType();
	}

	getOrgId(): number {
		return this.authService.getOrgId();
	}

	getDateAsStringFromDate(date: Date | null, addTime: boolean = false): string {
		if (!date) return "";
		let day = date.getDate();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();

		let dayString = day < 10 ? `0${day}` : `${day}`;
		let monthString = month < 10 ? `0${month}` : `${month}`;

		let dateString = `${year}-${monthString}-${dayString}`;

		if (addTime) {
			let hours = date.getHours();
			let minutes = date.getMinutes();
			let seconds = date.getSeconds();

			let hoursString = hours < 10 ? `0${hours}` : `${hours}`;
			let minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;
			let secondsString = seconds < 10 ? `0${seconds}` : `${seconds}`;

			dateString += ` ${hoursString}:${minutesString}:${secondsString}`;
		}

		return dateString;
	}

	getCurrentDateTime(): string {
		return new Date().toISOString();
	}

	getLoggedInUserId(): number {
		return this.authService.getLoggedInUserId();
	}
	getCurrentLanguage(): string {
		return this.languageService.getCurrentLang();
	}
	isEnglish(): boolean {
		return this.getCurrentLanguage() === "en";
	}
	getAppIds(): number[] {
		return this.authService.getRestaurantIds();
	}
	// dayName is 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
	getDayNumber(dayName: string): number {
		if (dayName === "Sun") return 0;
		if (dayName === "Mon") return 1;
		if (dayName === "Tue") return 2;
		if (dayName === "Wed") return 3;
		if (dayName === "Thu") return 4;
		if (dayName === "Fri") return 5;
		if (dayName === "Sat") return 6;
		return -1;
	}
	getDayName(day: number): string {
		const lang = this.languageService.getCurrentLang();
		if (day === 0) {
			return lang === "en" ? "Sunday" : "الأحد";
		}
		if (day === 1) {
			return lang === "en" ? "Monday" : "الإثنين";
		}
		if (day === 2) {
			return lang === "en" ? "Tuesday" : "الثلاثاء";
		}
		if (day === 3) {
			return lang === "en" ? "Wednesday" : "الأربعاء";
		}
		if (day === 4) {
			return lang === "en" ? "Thursday" : "الخميس";
		}
		if (day === 5) {
			return lang === "en" ? "Friday" : "الجمعة";
		}
		if (day === 6) {
			return lang === "en" ? "Saturday" : "السبت";
		}
		return "";
	}
	getDayShortName(day: number): string {
		if (day === 0) {
			return "Sun";
		}
		if (day === 1) {
			return "Mon";
		}
		if (day === 2) {
			return "Tue";
		}
		if (day === 3) {
			return "Wed";
		}
		if (day === 4) {
			return "Thu";
		}
		if (day === 5) {
			return "Fri";
		}
		if (day === 6) {
			return "Sat";
		}
		return "";
	}

	getDateDayName(date: string, isShortName = false): string {
		let d = new Date(date);
		if (isShortName) {
			return this.getDayShortName(d.getDay());
		} else return this.getDayName(d.getDay());
	}

	getNumberOfDaysBetweenTwoDates(date1: string, date2: string): number {
		const d1 = new Date(date1);
		const d2 = new Date(date2);

		const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

		const diffDays = Math.abs((d2.getTime() - d1.getTime()) / oneDay);

		return Math.round(diffDays);
	}

	getLoggedInUserAppsIds(): number[] {
		return this.authService.getRestaurantIds();
	}

	translate(term: string): string {
		return this.languageService.translate(term);
	}
	openConfirmationDialog(
		message: string,
		confirmMessage: string = "confirm",
		cancelMessage: string = "cancel",
		title: string = "confirm",
		callBackOnConfirm: (didConfirm: boolean) => void,
		additionalConfirmationButton?: {
			additionalConfirmationButtonMessage: string;
			additionalConfirmationButtonCallback: (...rest: any) => void;
		}
	) {
		const translatedMessage = this.languageService.translate(message);
		const translatedConfirmMessage =
			this.languageService.translate(confirmMessage);
		const translatedCancelMessage =
			this.languageService.translate(cancelMessage);

		const translatedTitle = this.languageService.translate(title);
		const panelClass =
			this.getCurrentLanguage() === "ar" ? "dialog-rtl" : "dialog-ltr";

		const confirmDialogRef = this.dialog.open(ConfirmActionDialogComponent, {
			minWidth: "400px",
			minHeight: "200px",
			data: {
				message: translatedMessage,
				confirmMessage: translatedConfirmMessage,
				cancelMessage: translatedCancelMessage,
				title: translatedTitle,
				additionalConfirmationButtonMessage:
					additionalConfirmationButton?.additionalConfirmationButtonMessage,
			},
			panelClass,
		});
		confirmDialogRef.afterClosed().subscribe((didConfirm) => {
			if (didConfirm) {
				if (typeof didConfirm === "object") {
					additionalConfirmationButton?.additionalConfirmationButtonCallback();
				} else {
					callBackOnConfirm(didConfirm);
				}
			} else {
			}
		});
	}

	/**
	 *
	 * @param supportMultipleApps : if true, the user can select multiple apps
	 * @param modelName  : the name of the model that the user is creating, used only for display
	 * @returns a promise that resolves to the selected app id or app ids if supportMultipleApps is true
	 * @summary the component that calls this function should have the SelectAppIdModule imported in its module
	 */
	getCreationAppIdOrAppIds(
		supportMultipleApps = false,
		modelName = "",
		headerTexts: string[] = []
	): Promise<number | number[] | null> {
		return new Promise((resolve, reject) => {
			const isYumealzUser = this.isYumealzUser();
			if (isYumealzUser) {
				resolve(null);
				return;
			}
			const app_ids = this.getAppIds().map((appId) => appId);

			if (app_ids.length == 1) {
				resolve(app_ids[0]);
				return;
			} else {
				const panelClass =
					this.getCurrentLanguage() === "ar" ? "dialog-rtl" : "dialog-ltr";

				const selectAppDialog = this.dialog.open(SelectAppIdComponent, {
					width: "600px",
					data: {
						modelName: modelName,
						supportMultiSelect: supportMultipleApps,
						headerTexts: headerTexts,
					},
					panelClass,
				});
				this.subscriptions.push(
					selectAppDialog.afterClosed().subscribe((apps_ids: number[]) => {
						if (apps_ids) {
							if (supportMultipleApps) {
								resolve(apps_ids);
								return;
							} else {
								resolve(apps_ids[0]);
								return;
							}
						}
					})
				);
			}
		});
	}
	getNestedValue(obj: any, path: string): any {
		return path.split(".").reduce((prev, curr) => {
			if (prev && curr.includes("[") && curr.includes("]")) {
				const [key, index] = curr.split(/[\[\]]/).filter(Boolean);
				return prev[key] ? prev[key][Number(index)] : null;
			}
			return prev ? prev[curr] : null;
		}, obj || self);
	}

	urlParamsToString(params: any) {
		if (!params) return "";
		let q = "?";
		for (const [key, value] of Object.entries(params)) {
			if (value) q += `${key}=${value}&`;
		}
		if (q.slice(-1) == "&") q = q.slice(0, -1);
		return q;
	}

	makeCamelCase(txt: string | undefined): string {
		if (!txt) return "";
		return txt.charAt(0).toLocaleUpperCase() + txt.slice(1);
	}
	compare(
		a: number | string | Date | undefined,
		b: number | string | Date | undefined,
		isAsc: boolean
	) {
		return (a! < b! ? -1 : 1) * (isAsc ? 1 : -1);
	}
	isYumealzRestaurant(restaurantId: number): boolean {
		if (environment.apiUrl.split("/").includes("dev-api.yumealz.com")) {
			return restaurantId === 25;
		} else if (environment.apiUrl.split("/").includes("api.yumealz.com")) {
			return restaurantId === 37;
		} else {
			// todo change this according to the local DB
			return false;
		}
	}
	getYumealzRestaurantId() {
		if (environment.apiUrl.split("/").includes("dev-api.yumealz.com")) {
			return 25;
		} else if (environment.apiUrl.split("/").includes("api.yumealz.com")) {
			return 37;
		} else {
			// this should never be reached
			console.error("Wrong API URL");
			return -1;
		}
	}
	getDeliveryWindowTime(dw: IDeliveryWindow) {
		return this.getCurrentLanguage() == "en"
			? `${dw.from_time} - ${dw.to_time}`
			: `${dw.from_time.replace("AM", "ص").replace("PM", "م")} - ${dw.to_time
					.replace("AM", "ص")
					.replace("PM", "م")}`;
	}
	chunkAnArray(array: any[], maxSize: number = 100): any[][] {
		let newArray = [];
		const numberOfArrays = Math.ceil(array.length / maxSize);
		let startIndex = 0;
		for (let index = 0; index < numberOfArrays; index++) {
			let endIndex =
				startIndex + maxSize < array.length ? startIndex + maxSize : undefined;
			newArray.push(array.slice(startIndex, endIndex));

			if (endIndex) startIndex = endIndex;
		}
		return newArray;
	}
	sortModelArrayByName(modelArray: any[]): any[] {
		return modelArray.sort((a: any, b: any) => {
			if (this.getCurrentLanguage() === "en") {
				if (a.name_en.toLowerCase() < b.name_en.toLowerCase()) {
					return -1;
				} else if (a.name_en.toLowerCase() > b.name_en.toLowerCase()) {
					return 1;
				} else {
					return 0;
				}
			} else {
				if (a.name_ar.toLowerCase() < b.name_ar.toLowerCase()) {
					return -1;
				} else if (a.name_ar.toLowerCase() > b.name_ar.toLowerCase()) {
					return 1;
				} else {
					return 0;
				}
			}
		});
	}
	// Helper function to check if all terms are present in a text with approximate matching
	containsAllTerms(
		text: string,
		terms: string[],
		maxLevenshteinDistance = 2
	): boolean {
		return terms.every((term) =>
			this.isWordInText(text, term, maxLevenshteinDistance)
		);
	}
	// Function to check if a word is in the text, using Levenshtein distance only when necessary
	isWordInText(
		text: string,
		term: string,
		maxLevenshteinDistance: number
	): boolean {
		const words = text.split(" ");

		for (const word of words) {
			if (
				word.includes(term) ||
				this.levenshteinDistance(word, term) <= maxLevenshteinDistance
			) {
				return true;
			}
		}
		return false;
	}

	levenshteinDistance(a: string, b: string): number {
		const alen = a.length;
		const blen = b.length;

		// Edge cases
		if (alen === 0) return blen;
		if (blen === 0) return alen;

		// Create a matrix
		const matrix: number[][] = Array.from({ length: blen + 1 }, () =>
			Array(alen + 1).fill(0)
		);

		// Initialize the matrix
		for (let i = 0; i <= blen; i++) {
			matrix[i][0] = i;
		}
		for (let j = 0; j <= alen; j++) {
			matrix[0][j] = j;
		}

		// Compute Levenshtein distance with a preference for exact matches
		for (let i = 1; i <= blen; i++) {
			for (let j = 1; j <= alen; j++) {
				const cost = a[j - 1] === b[i - 1] ? 0 : 2; // Penalize substitutions more heavily
				matrix[i][j] = Math.min(
					matrix[i - 1][j] + 1, // Deletion
					matrix[i][j - 1] + 1, // Insertion
					matrix[i - 1][j - 1] + cost // Substitution
				);
			}
		}

		return matrix[blen][alen];
	}

	getBackendMessage(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.http.get(this.workerApi, { responseType: 'text' })
				.pipe(
					tap((message: string) => {
						this.isUnderMaintenance = message === "true";
					})
				)
				.subscribe({
					next: () => {
						resolve();  // Resolve the promise when done
					},
					error: (error) => {
						console.error('Error fetching maintenance message', error);
						reject(error);  // Reject in case of an error
					}
				});
		});
	}

	async isBackendWorking():Promise<string> {
		try {
			await this.getBackendMessage();
			if (!this.isUnderMaintenance) {
				this.setDashboardUserProfile();
				return "false"
			} else {
				this.isUnderMaintenance === true
				const status = this.isUnderMaintenance ? "true" : "false";
				return status;
			}
			
		} catch (error) {
			console.error('Error during initialization', error);
		}
		return ""
	}	
	
	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}
}
