import {
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnInit,
} from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { AppService } from "../../services/app-service/app-service";

@Directive({
	selector: "[appHideElement]",
})
export class HideElementDirective implements OnInit {
	@Input("appHideElement") elementName: string = "";
	@Input() popUpMessage = "";
	@Input() newPathRouterLinks: string[] = [];
	@Input() anchorMessage = "";

	constructor(
		private el: ElementRef,
		private appService: AppService,
		public dialog: MatDialog
	) {}

	ngOnInit() {
		this.checkElementVisibility();
	}

	private checkElementVisibility() {
		if (!this.appService.shouldShowElement(this.elementName))
			this.el.nativeElement.style.display = "none";
	}

	@HostListener("click") onClick() {
		this.openDialog();
	}

	private openDialog(): void {
		this.appService.alertPopUp(
			this.popUpMessage,
			this.newPathRouterLinks,
			this.anchorMessage
		);
	}
}
