import {
	IAdBanner,
	IAppConfig,
	ICityDistricts,
	ICorporate,
	ICorporateLocation,
	ICreatePlanDataV3,
	ICustomerProfile2,
	ICustomerProfileReport,
	IDashboardUserProfile,
	IDetailedSubOrderV3,
	IDistrictGroup,
	IFinancialReport,
	IGroupedSubOrderFinancial,
	IInvoice,
	IOrder,
	IPermission,
	IOrganization,
	IPlanTag,
	IPlanV3,
	IPlanVersionV3,
	IRestaurantBranch,
	IRestaurantBranchAvailability,
	IRestaurantCombo,
	ISubOrderFinancial,
	IZone,
	MpRestaurantInvoice,
	IPermissionGroup,
	IOrgUserProfile,
	ICity,
} from "src/app/shared/models/classes.model";
import {
	CustomerProfile,
	IComplaint,
	IComplaintReason,
	IWallet,
	IWalletPoint,
} from "./../../models/classes.model";
import { ICaptain } from "src/app/report/components/sub-delivery/sub-delivery-datasource";
import { iVoucherGet } from "src/app/voucher/models/vouchers.models";
import { IVoucherGetResponse } from "./../../../voucher/models/vouchers.models";
import {
	IRestaurantUserExistsResponse,
	IRestaurantUserResponse,
} from "./../../../restaurants/modules/restaurants-users/models/restaurant-users.model";
import { CasheService } from "../cashe.service";
import { OrderStatus } from "./../app-service/app-service";
import {
	Restaurant,
	ISubscriptionV3,
	IPlanDateV3,
	ISubOrderV3,
	ILocation,
	IProduct,
	IRestaurantCategory,
	IDeliveryWindow,
	User,
} from "../../models/classes.model";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { Observable, map, of, pipe, take, tap } from "rxjs";
import { IDashboardStatistics } from "src/app/dashboard/models/dashboard.model";
import { IRestaurantUser } from "src/app/restaurants/modules/restaurants-users/models/restaurant-users.model";
import {
	IAPIComplaintsGetResponse,
	IAPICreateCaptain,
	IAPICreateComplaintReasonPostBody,
	IAPIGetComplaintsParams,
	IAPIReasonsGetResponse,
	IAPIReviewComplaintPatchBody,
	IAPIUpdateCaptain,
	IAPIWalletDepositPointsPostBody,
	IAPIWalletsGetResponse,
	IAPIWalletWithdrawPointsPostBody,
	IAPICreateComplaintBody,
	IAPIFinancialReportParams,
	IAPIGetOrdersResponse,
	IAPIGetGeneralParams,
	IAPICreateProductPostBody,
	IAPIAddEditRestaurantPostBody,
	IAPIAddEditVouchersParams,
	IAPIGetZonesResponse,
	IAPIGetOrdersParams,
	IAPIGetPlanTagsResponse,
	IAPIGetGetPlanTagsParams,
	IAPICreateUpdatePlanTagParams,
	IAPIGetVouchersParams,
	IAPIGetCooperateLocationsParams,
	IAPIGetCooperateLocationsResponse,
	IAPIGetCorporatesResponse,
	IAPIGetCorporatesParams,
	IAPIPostPatchCorporatesParams as IAPIPostPatchCorporatesParams,
	IAPIAddEditCorporateLocationParams,
	IAPIUploadBulkProductsResponse,
	IAPIGetRestaurantBranchesResponse,
	IAPIGetRestaurantBranchAvailabilityResponse,
	IAPICreateUpdateRestaurantBranchParams,
	IAPICreateUpdateRestaurantBranchAvailabilityParams,
	IAPICreateUpdateRestaurantComboParams,
	IAPIGetInvoicesParams,
	IAPIGetInvoicesResponse,
	IAPISendBulkInvoicesParams,
	IAPIAcceptRejectOrderParams,
	IAPIUpdateComboInventoryParams,
	IAPIUpdateProductInventoryParams,
	IAPIGetPlansResponse,
	IAPIUpdatePlanV2Params,
	IAPIDistrictGroupsGetResponse,
	IAPIUpdateDistrictGroupPostBody,
	IAPIGetCustomerLocationsResponse,
	IAPICreateUpdateCustomerLocationParams,
	IAPIApplySubscriptionVoucherParams,
	IAPIApplySubscriptionVoucherResponse,
	IAPIPaymentLinkResponse,
	IAPIHoldSubscriptionParams,
	IAPIResumeSubscriptionParams,
	IAPIGetCustomerProfilesParams,
	IAPIGetCustomerProfilesResponse,
	IAPIUpdateSubscriptionParams,
	IAPIGetMPRestaurantInvoiceParams,
	IAPIGetMPRestaurantInvoiceResponse,
	IAPIGetPaymentPeriodResponse,
	IAPICreateCustomerProfileParams,
	IAPISendNotificationBody,
	IAPIGenerateMpRestaurantInvoiceParams,
	IAPIGetSubOrdersFinancialParams,
	IAPIGetGroupedSubordersFinancialParams,
	IAPIGetAppConfigsResponse,
	IAPIUpdateAdBannerParams as IAPICreateUpdateAdBannerParams,
	IAPIGetPlansVeraionsResponse,
	IAPIGetPlanVersionsParams,
	IAPIGetOrganizationsParams,
	IAPIGetOrganizationsResponse,
	IAPIGetCitiesResponse,
	IAPIGetOrgUserProfilesResponse,
	IAPIGetOrgUserProfilesParams,
	IAPIUpdateOrganizationParams,
	IAPIUpdateOrgUserProfilesParams,
	IAPIGetSubscribablePlanDatesResponse,
	IAPIUpdateSubOrderParams,
	IAPIUpdateSubOrderNotReceivedParams,
	IAPIGetSubOrdersParams,
	IAPIGetSubOrderersResponse,
	IAPIGetCaptainsParams,
	IAPIGetCaptainsResponse,
	IAPIGetDeliveryWindowsResponse,
	IAPICreateUpdateZoneParams,
	IAPIGetDeliveryWindowsParams,
	IAPICreateUpdateDeliveryWindow,
	IAPIGetZonesParams,
	IAPIBulkUpdateOrgProfilesPermissionsParams,
	IAPICreateMenuParams,
	IAPIUpdateMenuDayOption,
	IAPIUpdateMenuDayParams,
	IAPIUpdateMenuParams,
	IAPIGetPlansParams,
	IAPIGetMenuPlansResponse,
	IAPICreateMenuPlanParams,
	IAPIUpdateMenuPlanParams,
	IAPIGetRestrictedDeliveryWindowsParams,
	IAPIDeliverOrderParams,
	IAPICancelOrderParams,
	IAPIUpdateCustomerProfileParams,
	IAPIGetLogisticsClientSuborders,
} from "./api-signatures.model";

import { IMenu, IMenuDay, IMenuPlan } from "src/app/menus/models/classes.model";
import { ILogisticsClientSuborder } from "src/app/delivery/_models/classes.model";

@Injectable({
	providedIn: "root",
})
export class YumealzApiService {
	readonly billOfQtyEndPoint = "dashboard/bill-of-quantity/";
	readonly subPackagingEndPoint = "dashboard/subscription-packaging/";
	readonly demPackagingEndPoint = "dashboard/on-demand-packaging/";
	readonly subDeliveryEndPoint = "dashboard/subscription-delivery/";
	readonly demDeliveryEndPoint = "dashboard/on-demand-delivery/";
	readonly subscriptionEndPoint = "dashboard/admin/subscriptions/";

	readonly plansV3EndPoint = "api/v3/dashboard/admin/plans/";
	readonly planVersionsV3EndPoint = "api/v3/dashboard/admin/plan-versions/";
	readonly organizationsEndPoint = "api/v1/dashboard/admin/organizations/";
	readonly PermissionEndPoint =
		"api/v1/dashboard/admin/organizations/permissions";
	readonly financeEndPoint = "dashboard/finance/";
	readonly customersEndPoint = "dashboard/customers/";
	readonly customerProfileEndPoint = "dashboard/admin/customers/";
	readonly usersEndPoint = "dashboard/admin/users/";
	readonly captainsEndPoint = "dashboard/admin/captains/";
	readonly assignCaptainEndPoint = "dashboard/admin/captains/assign/";
	readonly restaurantsEndPoint = "dashboard/admin/restaurants/";
	readonly deliveryWindowsEndPoint = "utilities/delivery-windows/";
	readonly subOrdersEndPoint = "dashboard/admin/sub-orders/";
	readonly logisticsClientsSubOrdersEndPoint =
		"dashboard/admin/logistics-clients/sub-orders/";
	readonly customerLocationsEndPoint = "dashboard/admin/locations/";
	readonly financeReport2EndPoint = "dashboard/detailed-finance/";
	readonly restaurantUsersEndPoint = "dashboard/admin/restaurants-users/";
	readonly createRestaurantUserEndPoint = "dashboard/admin/restaurants-users/";
	readonly restaurantUserExistsEndPoint = "dashboard/admin/users/does-exist/";
	readonly VouchersEndPoint = "dashboard/admin/vouchers/";
	readonly statisticsEndPoint = "dashboard/stats/";
	readonly walletsEndPoint = "dashboard/admin/wallets/";
	readonly complaintsReasonsEndPoint = "dashboard/admin/complaints-reasons/";
	readonly complaintsEndPoint = "dashboard/admin/complaints/";
	readonly financialReportEndPoint = "dashboard/financial-sub-orders/";
	readonly ordersEndPoint = "dashboard/admin/orders/";
	readonly cooperateLocationsEndPoint = "dashboard/admin/corporates/locations/";
	readonly corporatesEndPoint = "dashboard/admin/corporates/";
	readonly districtGroupsEndPoint =
		"dashboard/admin/utilities/district-groups/";
	readonly districtsEnPoint = "dashboard/admin/utilities/districts/";
	readonly notificationsEndPoint = "dashboard/admin/notifications/";
	// readonly restaurantProductsEndPoint = '/dashboard/admin/restaurants/1/products/';
	readonly appConfigEndPoint = "dashboard/admin/app-configs/";
	readonly bulkUpdateOrgPermissionsEndPoint =
		"dashboard/bulk-update-org-profiles-permission/";
	readonly miscConfigEndPoint = "utilities/configs/";

	private isCashedRestaurantCategoriesAvailable: boolean = false;
	private cachedRestaurantCategoriesId: number = -1;

	constructor(
		private httpClient: HttpClient,
		private casheService: CasheService
	) {}

	constructParams(params: any) {
		if (params.app_id === -1) delete params.app_id;
		if (params.app_id === -2) params.app_id = null;

		let urlParams = "";
		urlParams += "?";
		const keys = Object.keys(params);
		keys.forEach((key, index) => {
			const value = params[key as keyof IAPIGetCaptainsParams];
			// for app_id we don't care if the value is null thats why it is an exception
			if (key === "app_id" || (value !== null && value !== undefined)) {
				if (index != keys.length - 1) urlParams += `${key}=${value}&`;
				else urlParams += `${key}=${value}`;
			}
		});

		return urlParams;
	}

	getStatistics(
		restaurantId?: number,
		dateFrom?: string,
		dateTo?: string
	): Observable<IDashboardStatistics> {
		var q = "";
		if (restaurantId) {
			q += `restaurant=${restaurantId}&`;
		}
		if (dateFrom) {
			q += `from_date=${dateFrom}&`;
		}
		if (dateTo) {
			q += `to_date=${dateTo}`;
		}

		return this.httpClient.get<IDashboardStatistics>(
			`${environment.apiUrl}${this.statisticsEndPoint}?${q}`
		);
	}

	createPlanV3(data: ICreatePlanDataV3, file: File): Observable<IPlanV3> {
		const formData = new FormData();
		formData.append("image", file);
		formData.append("data", JSON.stringify(data));

		return this.httpClient.post<IPlanV3>(
			`${environment.newApiUrl}${this.plansV3EndPoint}`,
			formData
		);
	}

	createMenuPlan(
		data: IAPICreateMenuPlanParams,
		file: File
	): Observable<IMenuPlan> {
		const formData = new FormData();
		formData.append("image", file);
		formData.append("data", JSON.stringify(data));
		return this.httpClient.post<IMenuPlan>(
			`${environment.newApiUrl}api/v1/dashboard/admin/menued-plans/`,
			formData
		);
	}

	getMenuPlan(id: number): Observable<IMenuPlan> {
		return this.httpClient.get<IMenuPlan>(
			`${environment.newApiUrl}api/v1/dashboard/admin/menued-plans/${id}/`
		);
	}
	getMenuedPlanDataByKeys(
		planId: number,
		keys: string[]
	): Observable<Restaurant> {
		let urlParams = "?include=";
		keys.forEach((key, index) => {
			if (index !== keys.length - 1) {
				urlParams += `${key},`;
			} else urlParams += `${key}`;
		});
		return this.httpClient.get<Restaurant>(
			`${environment.apiUrl}dashboard/admin/menued-plans/${planId}${urlParams}`
		);
	}

	updateMenuPlan(
		id: number,
		data: IAPIUpdateMenuPlanParams,
		file?: File
	): Observable<IMenuPlan> {
		const formData = new FormData();
		if (file) formData.append("image", file);
		formData.append("data", JSON.stringify(data));
		return this.httpClient.patch<IMenuPlan>(
			`${environment.newApiUrl}api/v1/dashboard/admin/menued-plans/${id}/`,
			formData
		);
	}
	updatePlanV3(
		planId: number,
		data: IAPIUpdatePlanV2Params,
		file?: File
	): Observable<IPlanV3> {
		const formData = new FormData();
		if (file) formData.append("image", file);

		formData.append("data", JSON.stringify(data));

		return this.httpClient.patch<IPlanV3>(
			`${environment.newApiUrl}${this.plansV3EndPoint}${planId}/`,
			formData
		);
	}

	updatePlanDateV3(
		planId: number,
		planDateId: number,
		data: any
	): Observable<IPlanDateV3> {
		return this.httpClient.patch<IPlanDateV3>(
			`${environment.newApiUrl}${this.plansV3EndPoint}${planId}/plan-dates/${planDateId}/`,
			data
		);
	}

	getASubscriptionV3(
		subscriptionId: number,
		exclude?: string
	): Observable<ISubscriptionV3> {
		let url = `${environment.newApiUrl}api/v3/${this.subscriptionEndPoint}${subscriptionId}/`;
		if (exclude) {
			url += `?exclude=${exclude}`;
		}
		return this.httpClient.get<ISubscriptionV3>(url);
	}
	getSubscriptionSuborders(subscriptionId: number): Observable<ISubOrderV3[]> {
		let url = `${environment.newApiUrl}api/v3/${this.subscriptionEndPoint}${subscriptionId}/sub-orders/`;
		return this.httpClient.get<ISubOrderV3[]>(url);
	}

	reOpenSubscriptionV3(
		subscriptionId: number,
		appId: number | null
	): Observable<ISubscriptionV3> {
		let headers = new HttpHeaders();
		headers = headers.append("app-id", appId + "");
		return this.httpClient.patch<ISubscriptionV3>(
			`${environment.newApiUrl}api/v3/${this.subscriptionEndPoint}${subscriptionId}/re-open/`,
			{},
			{
				headers,
			}
		);
	}
	getPlanV3(planId: number): Observable<IPlanV3> {
		return this.httpClient.get<IPlanV3>(
			`${environment.newApiUrl}${this.plansV3EndPoint}${planId}/`
		);
	}
	transferSubscription(subscriptionId: number, data: any): Observable<any> {
		return this.httpClient.post<any>(
			`${environment.newApiUrl}api/v3/${this.subscriptionEndPoint}${subscriptionId}/transfer/`,
			data
		);
	}

	getListOfCustomerLocations(
		customerId: number
	): Observable<IAPIGetCustomerLocationsResponse> {
		return this.httpClient.get<IAPIGetCustomerLocationsResponse>(
			`${environment.apiUrl}${this.customerLocationsEndPoint}?page_size=1000&customer_profile=${customerId}`
		);
	}
	createCustomerLocation(
		params: IAPICreateUpdateCustomerLocationParams
	): Observable<ILocation> {
		const formData = new FormData();
		const image = params.image;
		if (image) formData.append("image", image);
		formData.append("data", JSON.stringify(params));

		return this.httpClient.post<ILocation>(
			`${environment.apiUrl}${this.customerLocationsEndPoint}`,
			formData
		);
	}

	updateCustomerLocation(
		locationId: number,
		params: IAPICreateUpdateCustomerLocationParams
	) {
		const formData = new FormData();
		const image = params.image;
		if (image) formData.append("image", image);
		formData.append("data", JSON.stringify(params));

		return this.httpClient.patch<ILocation>(
			`${environment.apiUrl}${this.customerLocationsEndPoint}${locationId}/`,
			formData
		);
	}
	// getListOfCaptains(onlyShowActive = false): Observable<any> {
	// 	let params = "?page=1&page_size=100000";
	// 	if (onlyShowActive) params += "&is_active=true";
	// 	return this.httpClient.get<any>(
	// 		`${environment.apiUrl}${this.captainsEndPoint}${params}`,
	// 		{}
	// 	);
	// }
	getPaginatedListOfCaptains(
		params: IAPIGetCaptainsParams
	): Observable<IAPIGetCaptainsResponse> {
		const urlParams = this.constructParams(params);
		return this.httpClient.get<IAPIGetCaptainsResponse>(
			`${environment.apiUrl}${this.captainsEndPoint}${urlParams}`,

			{}
		);
	}

	getACaptainProfile(captainId: number): Observable<ICaptain> {
		return this.httpClient.get<ICaptain>(
			`${environment.apiUrl}${this.captainsEndPoint}${captainId}/`
		);
	}

	createCaptainProfile(data: IAPICreateCaptain): Observable<ICaptain> {
		return this.httpClient.post<any>(
			`${environment.apiUrl}${this.captainsEndPoint}`,
			data
		);
	}

	updateCaptainProfile(
		captainId: number,
		data: IAPIUpdateCaptain
	): Observable<ICaptain> {
		return this.httpClient.patch<ICaptain>(
			`${environment.apiUrl}${this.captainsEndPoint}${captainId}/`,
			data
		);
	}

	// getListOfDeliveryWindows(
	// 	onlyShowActive = false
	// ): Observable<IDeliveryWindow[]> {
	// 	let params = "?page=1&page_size=100000";
	// 	if (onlyShowActive) params += "&is_active=true";
	// 	return this.httpClient.get<IDeliveryWindow[]>(
	// 		`${environment.apiUrl}${this.deliveryWindowsEndPoint}${params}`
	// 	);
	// }

	getPaginatedListOfDeliveryWindows(
		params: IAPIGetDeliveryWindowsParams
	): Observable<IAPIGetDeliveryWindowsResponse> {
		const urlParams = this.constructParams(params);
		return this.httpClient.get<IAPIGetDeliveryWindowsResponse>(
			`${environment.apiUrl}dashboard/admin/utilities/delivery-windows/${urlParams}`,
			{}
		);
	}

	getRestrictedDeliveryWindows(
		params: IAPIGetRestrictedDeliveryWindowsParams
	): Observable<IDeliveryWindow[]> {
		const urlParams = this.constructParams(params);
		return this.httpClient.get<IDeliveryWindow[]>(
			`${environment.apiUrl}dashboard/admin/utilities/restricted-delivery-windows/${urlParams}`,
			{}
		);
	}

	createDeliveryWindow(
		data: IAPICreateUpdateDeliveryWindow
	): Observable<IDeliveryWindow> {
		return this.httpClient.post<IDeliveryWindow>(
			`${environment.apiUrl}dashboard/admin/utilities/delivery-windows/`,
			data
		);
	}

	updateDeliveryWindow(
		deliveryWindowId: number,
		data: IAPICreateUpdateDeliveryWindow
	): Observable<IDeliveryWindow> {
		return this.httpClient.patch<IDeliveryWindow>(
			`${environment.apiUrl}dashboard/admin/utilities/delivery-windows/${deliveryWindowId}/`,
			data
		);
	}

	getDeliveryWindowById(id: number): Observable<IDeliveryWindow> {
		return this.httpClient.get<IDeliveryWindow>(
			`${environment.apiUrl}dashboard/admin/${this.deliveryWindowsEndPoint}${id}/`
		);
	}

	getListOfProducts(restaurantId: number): Observable<IProduct[]> {
		return this.httpClient.get<IProduct[]>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/products/`,
			{}
		);
	}

	getListOfDistrictGroups(): Observable<IAPIDistrictGroupsGetResponse> {
		return this.httpClient.get<IAPIDistrictGroupsGetResponse>(
			`${environment.apiUrl}${this.districtGroupsEndPoint}?page=1&page_size=1000/`,
			{}
		);
	}

	updateDistrictGroup(
		districtGroupId: number,
		data: IAPIUpdateDistrictGroupPostBody
	) {
		return this.httpClient.patch<IDistrictGroup>(
			`${environment.apiUrl}${this.districtGroupsEndPoint}${districtGroupId}/`,
			data
		);
	}

	getListOfDistricts(): Observable<ICityDistricts[]> {
		return this.httpClient.get<ICityDistricts[]>(
			`${environment.apiUrl}${this.districtsEnPoint}`,
			{}
		);
	}

	getListOfRestaurantCategories(
		restaurantId: number,
		forceRefresh: boolean = false
	): Observable<IRestaurantCategory[]> {
		if (
			!forceRefresh &&
			this.isCashedRestaurantCategoriesAvailable &&
			restaurantId == this.cachedRestaurantCategoriesId
		) {
			return of(this.casheService.getCachedRestaurantCategories());
		} else {
			const observable = this.httpClient.get<IRestaurantCategory[]>(
				`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/categories/`,
				{}
			);

			return observable.pipe(
				tap((value) => {
					this.casheService.setCachedRestaurantCategories(value);
					this.isCashedRestaurantCategoriesAvailable = true;
					this.cachedRestaurantCategoriesId = restaurantId;
				})
			);
		}
	}

	// todo remove this function
	getListOfPlanTags(): Observable<any[]> {
		return this.httpClient.get<any[]>(
			`${environment.newApiUrl}api/v3/dashboard/admin/plan-tags/?page=1&page_size=1000`,
			{}
		);
	}

	newGetPlanTags(
		params?: IAPIGetGetPlanTagsParams
	): Observable<IAPIGetPlanTagsResponse> {
		// todo test the params
		let q = "";
		if (params) {
			q += "?";
			const keys = Object.keys(params);
			keys.forEach((key, index) => {
				if (index != key.length - 1)
					q += `${key}=${params[key as keyof IAPIGetGetPlanTagsParams]}&`;
				else q += `${key}=${params[key as keyof IAPIGetGetPlanTagsParams]}`;
			});
		}
		return this.httpClient.get<IAPIGetPlanTagsResponse>(
			`${environment.newApiUrl}api/v3/dashboard/admin/plan-tags/${q}`
		);
	}

	createPlanTagV3(data: IAPICreateUpdatePlanTagParams): Observable<IPlanTag> {
		const formData = new FormData();
		const image = data.image;
		if (image) formData.append("image", image);
		formData.append("data", JSON.stringify(data));

		return this.httpClient.post<any>(
			`${environment.newApiUrl}api/v3/dashboard/admin/plan-tags/`,
			formData,
			{
				headers: {
					"app-id": `${data.app_id}`,
				},
			}
		);
	}

	updatePlanTagV3(planTagId: number, data: IAPICreateUpdatePlanTagParams) {
		const formData = new FormData();
		const image = data.image;
		if (image) formData.append("image", image);
		formData.append("data", JSON.stringify(data));

		return this.httpClient.patch<any>(
			`${environment.newApiUrl}api/v3/dashboard/admin/plan-tags/${planTagId}/`,
			formData,
			{
				headers: {
					"app-id": `${data.app_id}`,
				},
			}
		);
	}
	assignCaptain(
		subOrderIds: number[],
		captainId: number | null,
		logisticsProviderId: number,
		cityId: number
	): Observable<any | Error> {
		return this.httpClient.post(
			`${environment.apiUrl}${this.assignCaptainEndPoint}`,
			{
				sub_order_ids: subOrderIds,
				captain_id: captainId,
				city_id: cityId,
			},
			{
				headers: {
					"app-id": logisticsProviderId + "",
				},
			}
		);
	}

	assignCaptainOnDemand(ordersIds: number[], captainId: number | null) {
		return this.httpClient.post(
			`${environment.apiUrl}dashboard/admin/captains/on-demand/assign/`,
			{
				order_ids: ordersIds,
				captain_id: captainId,
			}
		);
	}

	cancelSubscriptionV3(
		subscription: ISubscriptionV3,
		params?: any
	): Observable<any> {
		return this.httpClient.post(
			`${this._cancelSubscriptionEndPoint(subscription.id)}`,
			{
				...params,
				app_id: subscription.app_id,
			}
		);
	}

	cancelBulkSubscriptionV3(subscriptions_ids?: any): Observable<any> {
		return this.httpClient.post(`${this._cancelBulkSubscriptionEndPoint()}`, {
			subscriptions_ids,
		});
	}

	cancelSubOrderV3(
		subscriptionId: number,
		subOrderId: number,
		appId: number | undefined | null
	): Observable<any> {
		return this.httpClient.post(
			`${this._cancelSubOrderEndPoint(subscriptionId, subOrderId)}`,
			{ app_id: appId }
		);
	}

	reOpenSubOrderV3(
		subscriptionId: number,
		subOrderId: number,
		appId: number | undefined | null
	): Observable<any> {
		return this.httpClient.patch(
			`${this._reOpenSubOrderEndPoint(subscriptionId, subOrderId)}`,
			{ app_id: appId }
		);
	}

	finishSubscriptionV3(subscriptionId: number): Observable<any> {
		return this.httpClient.post(
			`${this._finishSubscriptionEndPoint(subscriptionId)}`,
			{}
		);
	}

	holdSubscriptionV3(
		subscription: ISubscriptionV3,
		params: IAPIHoldSubscriptionParams
	): Observable<ISubscriptionV3> {
		return this.httpClient.post<ISubscriptionV3>(
			this._postponeSubscriptionEndPoint(subscription.id),
			{
				...params,
				resume_dates: [],
				app_id: subscription.app_id,
			}
		);
	}
	resumeSubscriptionV3(
		subscription: ISubscriptionV3,
		params: IAPIResumeSubscriptionParams
	): Observable<ISubscriptionV3> {
		return this.httpClient.post<ISubscriptionV3>(
			this._postponeSubscriptionEndPoint(subscription.id),
			{ ...params, dates: [], app_id: subscription.app_id }
		);
	}
	getSummaryFinanceReport(
		restaurantId: number,
		fromDate: string,
		toDate: string,
		status: string,
		isComplained: boolean
	): Observable<any> {
		return this.httpClient.post(
			`${environment.apiUrl}${this.financeEndPoint}`,
			{
				restaurant_id: restaurantId,
				from_date: fromDate,
				to_date: toDate,
				status: status,
				is_complained: isComplained,
			}
		);
	}

	getCustomers(
		page_size: number,
		page: number,
		searchText?: string
	): Observable<any> {
		let searchFilter = "";
		if (searchText) searchFilter = `&search=${searchText}`;
		return this.httpClient.get<any>(
			`${environment.apiUrl}${
				this.customersEndPoint
			}?page_size=${page_size}&page=${page + 1}${searchFilter}`,
			{}
		);
	}

	getCustomerProfilesReport(
		page_size: number,
		page: number,
		searchText?: string
	): Observable<ICustomerProfileReport> {
		let searchFilter = "";
		if (searchText) searchFilter = `&search=${searchText}`;
		return this.httpClient.get<any>(
			`${environment.apiUrl}${this.customersEndPoint}?page_size=${page_size}&page=${page}${searchFilter}`,
			{}
		);
	}

	getCustomerProfiles(
		params: IAPIGetCustomerProfilesParams
	): Observable<IAPIGetCustomerProfilesResponse> {
		let q = "";
		if (params) {
			q += "?";
			const keys = Object.keys(params);
			keys.forEach((key, index) => {
				if (params[key as keyof IAPIGetCustomerProfilesParams]) {
					if (index != key.length - 1)
						q += `${key}=${
							params[key as keyof IAPIGetCustomerProfilesParams]
						}&`;
					else
						q += `${key}=${params[key as keyof IAPIGetCustomerProfilesParams]}`;
				}
			});
		}
		return this.httpClient.get<IAPIGetCustomerProfilesResponse>(
			`${environment.apiUrl}${this.customerProfileEndPoint}${q}`
		);
	}

	getCustomerAccount(id: number): Observable<User> {
		return this.httpClient.get<User>(
			`${environment.apiUrl}${this.usersEndPoint}${id}/`
		);
	}
	activateCustomerProfile(
		id: number,
		appId: number
	): Observable<CustomerProfile> {
		return this.httpClient.post<CustomerProfile>(
			`${environment.apiUrl}${this.customerProfileEndPoint}${id}/activate/`,
			{},
			{
				headers: {
					"app-id": `${appId}`,
				},
			}
		);
	}
	toggleBlockCustomerProfile(
		id: number,
		appId: number
	): Observable<CustomerProfile> {
		return this.httpClient.post<CustomerProfile>(
			`${environment.apiUrl}${this.customerProfileEndPoint}${id}/toggle-block/`,
			{},
			{
				headers: {
					"app-id": `${appId}`,
				},
			}
		);
	}

	getCustomerProfile(id: number): Observable<CustomerProfile> {
		return this.httpClient.get<CustomerProfile>(
			`${environment.apiUrl}${this.customerProfileEndPoint}${id}/`
		);
	}

	getSubscriptionsV3(
		page_size: number,
		page: number,
		status?: string,
		restaurantId?: number,
		searchText?: string,
		from_date?: string,
		to_date?: string,
		customerId?: number,
		planId?: number,
		sourceFilter?: "mp" | "saas" | "all",
		serviceType?: "delivery" | "pickup" | "all",
		is_paid?: boolean,
		cities?: number[],
		firstSubscriptionOnly?: boolean | string,
		exclude?: string,
		include?: string
	): Observable<any> {
		let filter = "";

		if (exclude) filter += `&exclude=${exclude}`;
		if (include) filter += `&include=${include}`;

		// todo remove this code by Aug 30 2023 as all stored statuses will be updated by then
		// modify old statuses to new ones
		if (typeof status === "undefined" || status == "undefined")
			status = undefined;
		if (status === "active") status = "confirmed";
		if (status == "inactive")
			status = "finished,cancelled_by_operation,cancelled_by_customer";
		// todo end of code snippet to remove

		if (status === "all") status = "";

		if (firstSubscriptionOnly === true) {
			filter += `&is_first_subscription=true`;
		}
		if (firstSubscriptionOnly === false) {
			filter += `&is_first_subscription=false`;
		}
		if (restaurantId) filter += `&plan__restaurants=${restaurantId}`;

		if (searchText) filter += `&search=${searchText}`;

		if (from_date) filter += `&from_date=${from_date}`;
		if (to_date) filter += `&to_date=${to_date}`;
		if (typeof is_paid !== "undefined") filter += `&is_paid=${is_paid}`;
		if (cities && cities.length) {
			const citiesString = cities.join(",");
			filter += `&city__in=${citiesString}`;
		}

		if (sourceFilter) {
			if (sourceFilter == "mp") {
				filter += `&app_id=${null}`;
			} else if (sourceFilter == "saas") {
				if (restaurantId) filter += `&app_id=${restaurantId}`;
				else {
					// 	this.matSnackBar.open(
					// 	"You can not apply app source filter without selecting a restaurant",
					// 	"Close",
					// 	{
					// 		duration: 3000,
					// 	}
					// );
				}
			}
		}
		if (customerId) filter += `&customer_profile=${customerId}`;

		if (planId) filter += `&plan=${planId}`;

		if (serviceType)
			if (serviceType === "delivery") filter += `&is_pickup=false`;
			else if (serviceType === "pickup") filter += `&is_pickup=true`;

		if (status) filter += `&status__in=${status}`;

		return this.httpClient.get<any>(
			`${environment.newApiUrl}api/v3/${
				this.subscriptionEndPoint
			}?page_size=${page_size}&page=${page + 1}${filter}`,
			{}
		);
	}

	getSubOrdersV3(
		filters: IAPIGetSubOrdersParams
	): Observable<IAPIGetSubOrderersResponse> {
		if (filters.service_type === "all") delete filters.service_type;

		if (filters.captain === -1) delete filters.captain;
		// -2 means null which means not assigned
		if (filters.captain === -2) filters.captain = null;

		if (filters.delivery_window === -1) delete filters.delivery_window;
		// -2 means null which means not assigned
		if (filters.delivery_window === -2) filters.delivery_window = null;

		if (filters.city__in === undefined) delete filters.city__in;
		// -2 means null which means not assigned

		// check the params values and modify them here
		let q = "";
		if (filters) {
			q += "?";
			const keys = Object.keys(filters);
			keys.forEach((key, index) => {
				const value = filters[key as keyof IAPIGetSubOrdersParams];
				// for captain and app_id we don't care if the value is null thats why it is an exception
				if (key === "captain" || key === "app_id" || value) {
					if (index != keys.length - 1) q += `${key}=${value}&`;
					else q += `${key}=${value}`;
				}
			});
		}
		return this.httpClient.get<IAPIGetSubOrderersResponse>(
			`${environment.newApiUrl}api/v3/${this.subOrdersEndPoint}${q}`
		);
	}

	getLogisticsClientsSubOrders(
		filters: IAPIGetSubOrdersParams,
		logisticsProviderId: number
	): Observable<IAPIGetLogisticsClientSuborders> {
		if (filters.service_type === "all") delete filters.service_type;

		if (filters.captain === -1) delete filters.captain;
		// -2 means null which means not assigned
		if (filters.captain === -2) filters.captain = null;

		if (filters.delivery_window === -1) delete filters.delivery_window;
		// -2 means null which means not assigned
		if (filters.delivery_window === -2) filters.delivery_window = null;

		if (filters.city__in === undefined) delete filters.city__in;
		// -2 means null which means not assigned

		if (filters.app_id === null) delete filters.app_id;
		// check the params values and modify them here
		let q = "";
		if (filters) {
			q += "?";
			const keys = Object.keys(filters);
			keys.forEach((key, index) => {
				const value = filters[key as keyof IAPIGetSubOrdersParams];
				// for captain we don't care if the value is null thats why it is an exception
				if (key === "captain" || value) {
					if (index != keys.length - 1) q += `${key}=${value}&`;
					else q += `${key}=${value}`;
				}
			});
		}
		return this.httpClient.get<IAPIGetLogisticsClientSuborders>(
			`${environment.newApiUrl}api/v1/${this.logisticsClientsSubOrdersEndPoint}${q}`,
			{
				headers: {
					"app-id": logisticsProviderId + "",
				},
			}
		);
	}

	getSubOrderV3(id: number): Observable<IDetailedSubOrderV3> {
		return this.httpClient.get<IDetailedSubOrderV3>(
			`${environment.newApiUrl}api/v3/${this.subOrdersEndPoint}${id}/`
		);
	}

	getPlanVersionV3(id: number): Observable<IPlanVersionV3> {
		return this.httpClient.get<IPlanVersionV3>(
			`${environment.newApiUrl}${this.planVersionsV3EndPoint}${id}/`
		);
	}

	getMenuPlans(params: IAPIGetPlansParams) {
		let q = "";
		if (params) {
			q += "?";
			const keys = Object.keys(params);
			keys.forEach((key, index) => {
				if (params[key as keyof IAPIGetPlansParams]) {
					if (index != keys.length - 1)
						q += `${key}=${params[key as keyof IAPIGetPlansParams]}&`;
					else q += `${key}=${params[key as keyof IAPIGetPlansParams]}`;
				}
			});

			if (q[q.length - 1] === "&") q = q.slice(0, -1);
			if (q == "?") q = "";
		}

		return this.httpClient.get<IAPIGetMenuPlansResponse>(
			`${environment.newApiUrl}api/v1/dashboard/admin/menued-plans/${q}`
		);
	}

	getPlansV3(
		page_size: number,
		page: number,
		isActive?: boolean | null,
		restaurantId?: number | null,
		isTemplate?: boolean | null,
		isArchived?: boolean | null,
		searchText?: string,
		isAll: boolean = false
	): Observable<IAPIGetPlansResponse> {
		let restaurantFilter = "";
		let searchFilter = "";
		let activeFilter = "";
		let archiveFilter = "";
		let templateFilter = "";
		let numOfDaysFilter = "";

		if (restaurantId) restaurantFilter = `&restaurants=${restaurantId}`;
		// if (numOfDays) numOfDaysFilter = `&num_of_days=${numOfDays}`;
		if (isActive !== null) activeFilter = `&is_active=${isActive}`;
		if (isTemplate !== null) templateFilter = `&is_template=${isTemplate}`;
		if (isArchived !== null) archiveFilter = `&is_archived=${isArchived}`;
		if (searchText) searchFilter = `&search=${searchText}`;

		if (isAll) {
			searchFilter += "&all=true";
		}

		return this.httpClient.get<IAPIGetPlansResponse>(
			`${environment.newApiUrl}${
				this.plansV3EndPoint
			}?page_size=${page_size}&page=${
				page + 1
			}${restaurantFilter}${searchFilter}${numOfDaysFilter}${activeFilter}${templateFilter}${archiveFilter}`,
			{}
		);
	}

	getPlanVersionsV3(
		params: IAPIGetPlanVersionsParams
	): Observable<IAPIGetPlansVeraionsResponse> {
		if (
			typeof params.plan__is_archived === "undefined" ||
			(params.plan__is_archived as any) === ""
		)
			delete params.plan__is_archived;
		let urlParams = "?";

		for (const key in params) {
			const element = params[key as keyof IAPIGetPlanVersionsParams];
			if (typeof element !== "undefined" && element !== null) {
				urlParams += `${key}=${
					params[key as keyof IAPIGetPlanVersionsParams]
				}&`;
			}
		}

		urlParams = urlParams.slice(0, -1);

		return this.httpClient.get<IAPIGetPlansVeraionsResponse>(
			`${environment.newApiUrl}${this.planVersionsV3EndPoint}${urlParams}`,
			{}
		);
	}
	bulkUpdateOrgPermissions(params: IAPIBulkUpdateOrgProfilesPermissionsParams) {
		return this.httpClient.post(
			`${environment.newApiUrl}api/v1/${this.bulkUpdateOrgPermissionsEndPoint}`,
			params
		);
	}
	getOrganizations(
		params: IAPIGetOrganizationsParams
	): Observable<IAPIGetOrganizationsResponse> {
		let urlParams = "?";
		for (const key in params) {
			const element = params[key as keyof IAPIGetOrganizationsParams];
			if (typeof element !== "undefined" && element !== null) {
				urlParams += `${key}=${
					params[key as keyof IAPIGetOrganizationsParams]
				}&`;
			}
		}
		urlParams = urlParams.slice(0, -1);

		return this.httpClient.get<IAPIGetOrganizationsResponse>(
			`${environment.newApiUrl}${this.organizationsEndPoint}${urlParams}`,

			{}
		);
	}

	getOrganization(id: number): Observable<IOrganization> {
		return this.httpClient.get<IOrganization>(
			`${environment.newApiUrl}${this.organizationsEndPoint}${id}/`
		);
	}

	getAllPermissions(): Observable<IPermission[]> {
		return this.httpClient.get<IPermission[]>(
			`${environment.newApiUrl}${this.PermissionEndPoint}/`
		);
	}

	getGroupedPermissions(tag: string): Observable<IPermissionGroup[]> {
		return this.httpClient.get<IPermissionGroup[]>(
			`${environment.newApiUrl}api/v1/dashboard/admin/organizations/permission-groups/?tag=${tag}`
		);
	}

	createOrganization(
		data: IAPIUpdateOrganizationParams
	): Observable<IOrganization> {
		const formData = new FormData();
		const image = data.logo;
		if (image) formData.append("logo", image);
		formData.append("data", JSON.stringify(data));

		return this.httpClient.post<IOrganization>(
			`${environment.newApiUrl}${this.organizationsEndPoint}`,
			formData
		);
	}

	updateOrganization(
		id: number,
		data: IAPIUpdateOrganizationParams
	): Observable<IOrganization> {
		const formData = new FormData();
		const image = data.logo;
		if (image) formData.append("logo", image);
		formData.append("data", JSON.stringify(data));

		return this.httpClient.patch<IOrganization>(
			`${environment.newApiUrl}${this.organizationsEndPoint}${id}/`,
			formData
		);
	}

	getOrgUserProfiles(
		orgId: number,
		params: IAPIGetOrgUserProfilesParams
	): Observable<IAPIGetOrgUserProfilesResponse> {
		let urlParams = "?";
		for (const key in params) {
			const element = params[key as keyof IAPIGetOrgUserProfilesParams];
			if (typeof element !== "undefined" && element !== null) {
				urlParams += `${key}=${
					params[key as keyof IAPIGetOrgUserProfilesParams]
				}&`;
			}
		}
		urlParams = urlParams.slice(0, -1);

		return this.httpClient.get<IAPIGetOrgUserProfilesResponse>(
			`${environment.newApiUrl}api/v1/dashboard/admin/organizations/${orgId}/profiles${urlParams}`,
			{}
		);
	}

	getOrgUserProfile(
		orgId: number,
		profileId: number
	): Observable<IOrgUserProfile> {
		return this.httpClient.get<IOrgUserProfile>(
			`${environment.newApiUrl}api/v1/dashboard/admin/organizations/${orgId}/profiles/${profileId}`,
			{}
		);
	}

	createOrgUserProfile(
		orgId: number,
		data: IAPIUpdateOrgUserProfilesParams
	): Observable<IOrgUserProfile> {
		return this.httpClient.post<IOrgUserProfile>(
			`${environment.newApiUrl}api/v1/dashboard/admin/organizations/${orgId}/profiles/`,
			data
		);
	}

	updateOrgUserProfile(
		orgId: number,
		profileId: number,
		data: IAPIUpdateOrgUserProfilesParams
	): Observable<IOrgUserProfile> {
		return this.httpClient.patch<IOrgUserProfile>(
			`${environment.newApiUrl}api/v1/dashboard/admin/organizations/${orgId}/profiles/${profileId}/`,
			data
		);
	}

	getListOfRestaurants(): Observable<Restaurant[]> {
		return this.httpClient.get<Restaurant[]>(
			`${environment.apiUrl}${this.restaurantsEndPoint}`,
			{
				// todo complete
			}
		);
	}

	getRestaurantDataByKeys(
		restaurantId: number,
		keys: string[]
	): Observable<Restaurant> {
		let urlParams = "?include=";
		keys.forEach((key, index) => {
			if (index !== keys.length - 1) {
				urlParams += `${key},`;
			} else urlParams += `${key}`;
		});
		return this.httpClient.get<Restaurant>(
			`${environment.apiUrl}${this.restaurantsEndPoint}${restaurantId}${urlParams}`
		);
	}

	getRestaurant(id: number): Observable<Restaurant> {
		return this.httpClient.get<Restaurant>(
			`${environment.apiUrl}${this.restaurantsEndPoint}${id}/`
		);
	}
	getAppConfigs(restaurantId: number): Observable<IAPIGetAppConfigsResponse> {
		return this.httpClient.get<IAPIGetAppConfigsResponse>(
			`${environment.apiUrl}${this.appConfigEndPoint}?restaurant=${restaurantId}`
		);
	}
	createAppConfig(appConfig: IAppConfig): Observable<IAppConfig> {
		const formData = new FormData();
		formData.append("data", JSON.stringify(appConfig));
		return this.httpClient.post<IAppConfig>(
			`${environment.apiUrl}${this.appConfigEndPoint}`,
			formData
		);
	}
	updateAppConfig(
		appConfig: IAppConfig,
		deleteDeliveryByYumealz: boolean = true
	): Observable<IAppConfig> {
		const formData = new FormData();

		const referral_image_en = appConfig.referral_image_en;
		const referral_image_ar = appConfig.referral_image_ar;

		let payLoad: any = appConfig;

		delete payLoad.ads_banners;
		if (deleteDeliveryByYumealz) delete payLoad.delivery_by_yumealz;

		formData.append("data", JSON.stringify(payLoad));
		if (referral_image_en)
			formData.append("referral_image_en", referral_image_en);

		if (referral_image_ar)
			formData.append("referral_image_ar", referral_image_ar);

		return this.httpClient.patch<IAppConfig>(
			`${environment.apiUrl}${this.appConfigEndPoint}${appConfig.id}/`,
			formData,
			{
				headers: {
					"app-id": `${appConfig.restaurant}`,
				},
			}
		);
	}

	updateAddBanner(
		appConfigId: number,
		adBannerId: number,
		params: IAPICreateUpdateAdBannerParams
	): Observable<IAdBanner> {
		const formData = new FormData();
		const image_en = params.image_en;
		const image_ar = params.image_ar;

		formData.append("data", JSON.stringify(params));
		if (image_en) formData.append("image_en", image_en);
		if (image_ar) formData.append("image_ar", image_ar);
		return this.httpClient.patch<IAdBanner>(
			`${environment.apiUrl}${this.appConfigEndPoint}${appConfigId}/ads-banners/${adBannerId}/`,
			formData,
			{
				headers: {
					"app-id": `${params.app_id}`,
				},
			}
		);
	}
	deleteAdBanner(
		appConfigId: number,
		adBannerId: number,
		app_id: number | null
	): Observable<any> {
		return this.httpClient.delete<any>(
			`${environment.apiUrl}${this.appConfigEndPoint}${appConfigId}/ads-banners/${adBannerId}/`,
			{
				headers: {
					"app-id": `${app_id}`,
				},
			}
		);
	}
	createAdBanner(
		appConfigId: number,
		params: IAPICreateUpdateAdBannerParams
	): Observable<IAdBanner> {
		const formData = new FormData();
		const image_en = params.image_en;
		const image_ar = params.image_ar;

		formData.append("data", JSON.stringify(params));
		if (image_en) formData.append("image_en", image_en);
		if (image_ar) formData.append("image_ar", image_ar);
		return this.httpClient.post<IAdBanner>(
			`${environment.apiUrl}${this.appConfigEndPoint}${appConfigId}/ads-banners/`,
			formData,
			{
				headers: {
					"app-id": `${params.app_id}`,
				},
			}
		);
	}
	getConfigValues(): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.apiUrl}${this.miscConfigEndPoint}`
		);
	}
	getPlanDate(planId: number, date: string): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.newApiUrl}${this.plansV3EndPoint}${planId}/plan-dates/date/${date}/`,
			{}
		);
	}
	getPlanDateWIthId(planId: number, dateId: number): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.newApiUrl}${this.plansV3EndPoint}${planId}/plan-dates/${dateId}/`,
			{}
		);
	}

	getSubscribablePlanDates(
		planId: number,
		planVersionId: number,
		startDate: string
	) {
		return this.httpClient.get<IAPIGetSubscribablePlanDatesResponse>(
			`${environment.newApiUrl}${this.plansV3EndPoint}${planId}/plan-dates/subscribable/?plan_version_id=${planVersionId}&start_date=${startDate}`,
			{}
		);
	}
	addSubOrder(
		subscriptionId: number,
		planDateId: number,
		planOptionsIds: number[],
		date: string,
		appId?: number
	): Observable<ISubOrderV3> {
		return this.httpClient.post<ISubOrderV3>(
			`${environment.newApiUrl}api/v3/dashboard/admin/subscriptions/${subscriptionId}/sub-orders/add/`,
			{
				plan_date_id: planDateId,
				plan_options_ids: planOptionsIds,
				date: date,
				app_id: appId,
			}
		);
	}

	editSubOrder(
		subOrderId: number,
		subscriptionId: number,
		params: IAPIUpdateSubOrderParams
	): Observable<ISubOrderV3> {
		return this.httpClient.patch<ISubOrderV3>(
			`${environment.newApiUrl}api/v3/dashboard/admin/subscriptions/${subscriptionId}/sub-orders/${subOrderId}/update/`,
			params
		);
	}
	editSubOrderNotReceivedStatus(
		subOrderId: number,
		subscriptionId: number,
		params: IAPIUpdateSubOrderNotReceivedParams
	) {
		return this.httpClient.post(
			`${environment.newApiUrl}api/v3/dashboard/admin/subscriptions/${subscriptionId}/sub-orders/${subOrderId}/not-received/`,
			params
		);
	}

	updateSubscription(
		subscription: ISubscriptionV3,
		params: IAPIUpdateSubscriptionParams
	): Observable<ISubscriptionV3> {
		return this.httpClient.patch<ISubscriptionV3>(
			`${environment.newApiUrl}api/v3/dashboard/admin/subscriptions/${subscription.id}/`,
			{ ...params, delivery_window_id: null, app_id: subscription.app_id }
		);
	}

	deliverSubOrder(
		subscriptionId: number,
		subOrderId: number,
		date: Date,
		appId: number | undefined | null = null,
		notify_customer: boolean = false
	): Observable<ISubOrderV3> {
		return this.httpClient.post<ISubOrderV3>(
			`${environment.newApiUrl}api/v3/dashboard/admin/subscriptions/${subscriptionId}/sub-orders/${subOrderId}/deliver/`,
			{
				delivered_at: date,
				app_id: appId,
				notify_customer: notify_customer,
			}
		);
	}

	bulkDeliverSubOrder(
		subOrdersIds: number[],
		date: Date,
		notify_customer: boolean = false,
		appId: number,
		cityId: number
	): Observable<ISubOrderV3> {
		return this.httpClient.post<ISubOrderV3>(
			`${environment.newApiUrl}api/v3/dashboard/admin/sub-orders/bulk-deliver/`,
			{
				delivered_at: date,
				notify_customer: notify_customer,
				sub_orders_ids: subOrdersIds,
				city_id: cityId,
			},
			{
				headers: {
					"app-id": "" + appId,
				},
			}
		);
	}

	changeMeals(
		subscriptionId: number,
		subOrderId: number,
		planDateId: number,
		planOptionsIds: number[],
		appId?: number
	): Observable<ISubOrderV3> {
		return this.httpClient.post<ISubOrderV3>(
			`${environment.newApiUrl}api/v3/dashboard/admin/subscriptions/${subscriptionId}/sub-orders/${subOrderId}/change-meals/`,
			{
				plan_date_id: planDateId,
				options_ids: planOptionsIds,
				app_id: appId,
			}
		);
	}

	getDetailedFinanceReport(
		restaurant_id: number,
		date?: string,
		status: string = OrderStatus.delivered
	): Observable<any> {
		if (typeof date == "undefined")
			date = new Date().toISOString().split("T")[0];

		const url = `${environment.apiUrl}${this.financeReport2EndPoint}`;

		return this.httpClient.post(url, {
			restaurant_id: +restaurant_id,
			date: date,
			status: status,
			is_complained: false, //todo add filter
		});
	}
	getDeliveryReport(
		restaurant_id: number,
		type: string = "on_demand",
		date?: string,
		captain_id?: number
	): Observable<any> {
		if (typeof date == "undefined")
			date = new Date().toISOString().split("T")[0];

		let url;

		if (type == "subscription")
			url = `${environment.apiUrl}${this.subDeliveryEndPoint}`;
		else url = `${environment.apiUrl}${this.demDeliveryEndPoint}`;

		return this.httpClient.post(url, {
			restaurant_id: restaurant_id,
			type: type,
			date: date,
			status: OrderStatus.confirmed,
			captain_id,
		});
	}
	getPackagingReport(
		restaurant_id: number,
		type: "on_demand" | "subscription" = "on_demand",
		date?: string,
		status: string = OrderStatus.confirmed,
		is_pickup: boolean | number = -1,
		service_type?: "other_delivery",
		cities_ids?: number[],
		corporates_ids?: number[] | null,
		delivery_window_id?: number
	): Observable<any> {
		if (typeof date == "undefined")
			date = new Date().toISOString().split("T")[0];

		let url;

		if (type == "subscription")
			url = `${environment.apiUrl}${this.subPackagingEndPoint}`;
		else url = `${environment.apiUrl}${this.demPackagingEndPoint}`;

		let params: any = {
			restaurant_id: restaurant_id,
			type: type,
			date: date,
			status: status,
			is_pickup: is_pickup,
			cities_ids: cities_ids,
			corporates_ids,
			delivery_window_id,
		};

		if (corporates_ids === undefined) delete params.corporates_ids;
		if (delivery_window_id === undefined) delete params.delivery_window_id;
		if (is_pickup === -1) delete params.is_pickup;
		if (service_type) params.service_type = service_type;
		return this.httpClient.post(url, params);
	}
	// todo change this to follow the smae pattern as the other endpoints
	getBillOfQty(
		restaurant_id: number,
		type: "on_demand" | "subscription" = "on_demand",
		date?: string,
		to_date?: string,
		status:
			| "confirmed"
			| "delivered"
			| "not_received_by_customer"
			| "not_received_from_restaurant"
			| "all" = "confirmed",
		isGrouped: boolean = false,
		isPickup: number | boolean = -1,
		citiesIds: number[] = [],
		groupedByDescription: boolean = false,
		groupedBySize: boolean = false,
		corporatesIds: any = undefined,
		deliveryWindowId?: number
	): Observable<any> {
		if (typeof date == "undefined")
			date = new Date().toISOString().split("T")[0];

		const grouped = isGrouped ? 1 : 0;

		let params: any = {
			restaurant_id: restaurant_id,
			type: type,
			date: date,
			to_date: to_date,
			status: status,
			grouped: grouped,
			is_pickup: isPickup,
			cities_ids: citiesIds,
			grouped_by_description: groupedByDescription,
			grouped_by_size: groupedBySize,
			corporates_ids: corporatesIds,
			delivery_window_id: deliveryWindowId,
		};
		// -1 means all so we don't need to send it
		if (isPickup === -1) delete params.is_pickup;
		if (corporatesIds === undefined) delete params.corporates_ids;
		if (deliveryWindowId === undefined) delete params.delivery_window_id;

		return this.httpClient.post(
			`${environment.apiUrl}${this.billOfQtyEndPoint}`,
			params
		);
	}

	getAllRestaurantUsers(): Observable<IRestaurantUserResponse> {
		return this.httpClient.get<IRestaurantUserResponse>(
			`${environment.apiUrl}${this.restaurantUsersEndPoint}?page=1&page_size=1000`
		);
	}

	getRestaurantUser(userId: string): Observable<IRestaurantUser> {
		return this.httpClient.get<IRestaurantUser>(
			`${environment.apiUrl}${this.restaurantUsersEndPoint}${userId}`
		);
	}
	createRestaurantUser(restaurantUserPayload: any) {
		return this.httpClient.post(
			`${environment.apiUrl}${this.restaurantUsersEndPoint}`,
			restaurantUserPayload
		);
	}

	checkIfUserExists(
		phoneNumber: string
	): Observable<IRestaurantUserExistsResponse> {
		return this.httpClient.post<IRestaurantUserExistsResponse>(
			`${environment.apiUrl}${this.restaurantUserExistsEndPoint}`,
			{
				mobile_number: phoneNumber,
			}
		);
	}

	editRestaurantUser(userId: string, restaurantUserPayload: any) {
		return this.httpClient.patch(
			`${environment.apiUrl}${this.restaurantUsersEndPoint}${userId}/`,
			restaurantUserPayload
		);
	}

	getVouchers(params: IAPIGetVouchersParams): Observable<IVoucherGetResponse> {
		let url = `${environment.apiUrl}${this.VouchersEndPoint}?`;

		for (const key in params) {
			const element = params[key as keyof IAPIGetVouchersParams];
			if (
				typeof element !== "undefined" &&
				element !== null &&
				element !== "undefined"
			) {
				url += `&${key}=${params[key as keyof IAPIGetVouchersParams]}`;
			}
		}
		return this.httpClient.get<IVoucherGetResponse>(url);
	}

	getVoucher(id: number): Observable<iVoucherGet> {
		return this.httpClient.get<iVoucherGet>(
			`${environment.apiUrl}${this.VouchersEndPoint}${id}/`
		);
	}

	exportVoucher(tag?: string): Observable<iVoucherGet> {
		let tagValue = `?tag=${tag}`;
		return this.httpClient.get<iVoucherGet>(
			`${environment.apiUrl}${this.VouchersEndPoint}export/${
				tag ? tagValue : ""
			}`,
			{
				responseType: "blob" as "json",
			}
		);
	}

	importVouchers(file: any, appId: number | null): Observable<any> {
		const formData = new FormData();
		formData.append("file", file);
		let headers = new HttpHeaders();
		headers = headers.append("app-id", appId + "");
		return this.httpClient.post(
			`${environment.apiUrl}${this.VouchersEndPoint}import/`,
			formData,
			{
				headers,
			}
		);
	}

	getCooperateLocations(
		params: IAPIGetCooperateLocationsParams
	): Observable<IAPIGetCooperateLocationsResponse> {
		let url = `${environment.apiUrl}${this.cooperateLocationsEndPoint}?page_size=${params.page_size}&page=${params.page}`;
		if (params.search) url += `&search=${params.search}`;

		return this.httpClient.get<IAPIGetCooperateLocationsResponse>(url);
	}

	getACorporateLocation(id: number): Observable<ICorporateLocation> {
		return this.httpClient.get<ICorporateLocation>(
			`${environment.apiUrl}${this.cooperateLocationsEndPoint}${id}/`
		);
	}

	getCorporates(
		params: IAPIGetCorporatesParams
	): Observable<IAPIGetCorporatesResponse> {
		let urlParams = "?";
		// extract the params from the object
		for (const key in params) {
			const element = params[key as keyof IAPIGetCorporatesParams];
			if (typeof element !== "undefined" && element !== null) {
				urlParams += `${key}=${params[key as keyof IAPIGetCorporatesParams]}&`;
			}
		}
		urlParams = urlParams.slice(0, -1);

		return this.httpClient.get<IAPIGetCorporatesResponse>(
			`${environment.apiUrl}${this.corporatesEndPoint}${urlParams}`
		);
	}
	getACorporate(id: number): Observable<ICorporate> {
		return this.httpClient.get<ICorporate>(
			`${environment.apiUrl}${this.corporatesEndPoint}${id}/`
		);
	}

	createCorporate(data: IAPIPostPatchCorporatesParams): Observable<ICorporate> {
		let headers = new HttpHeaders();
		if (data.app_id) {
			headers = headers.append("app-id", data.app_id + "");
			delete data.app_id;
		}
		const formData = new FormData();
		const image = data.logo;
		if (image) formData.append("image", image);
		formData.append("data", JSON.stringify(data));

		return this.httpClient.post<ICorporate>(
			`${environment.apiUrl}${this.corporatesEndPoint}`,
			formData,
			{ headers }
		);
	}

	updateCorporate(
		id: number,
		data: IAPIPostPatchCorporatesParams
	): Observable<ICorporate> {
		const formData = new FormData();
		const image = data.logo;

		let headers = new HttpHeaders();
		if (data.app_id) {
			headers = headers.append("app-id", data.app_id + "");
			delete data.app_id;
		}
		if (image) formData.append("image", image);
		formData.append("data", JSON.stringify(data));

		return this.httpClient.patch<ICorporate>(
			`${environment.apiUrl}${this.corporatesEndPoint}${id}/`,
			formData,
			{
				headers,
			}
		);
	}

	createCorporateLocation(data: IAPIAddEditCorporateLocationParams) {
		return this.httpClient.post<ICorporateLocation>(
			`${environment.apiUrl}${this.cooperateLocationsEndPoint}`,
			data
		);
	}

	updateCorporateLocation(
		id: number,
		data: IAPIAddEditCorporateLocationParams
	) {
		return this.httpClient.patch<ICorporateLocation>(
			`${environment.apiUrl}${this.cooperateLocationsEndPoint}${id}/`,
			data
		);
	}
	// start of wallet end points
	getWallets(
		page_size: number,
		page: number,
		customerId: number | null = null,
		search: string | null = null
	): Observable<IAPIWalletsGetResponse> {
		let url = `${environment.apiUrl}${this.walletsEndPoint}?page_size=${page_size}&page=${page}`;

		if (customerId) url += `&customer_id=${customerId}`;
		if (search) url += `&search=${search}`;

		return this.httpClient.get<IAPIWalletsGetResponse>(url);
	}

	getWallet(id: number): Observable<IWallet> {
		return this.httpClient.get<IWallet>(
			`${environment.apiUrl}${this.walletsEndPoint}${id}/`
		);
	}

	depositPointToWallet(
		data: IAPIWalletDepositPointsPostBody
	): Observable<IWalletPoint> {
		return this.httpClient.post<IWalletPoint>(
			`${environment.apiUrl}${this.walletsEndPoint}deposit/`,
			data
		);
	}

	withdrawPointFromWallet(
		data: IAPIWalletWithdrawPointsPostBody
	): Observable<IWalletPoint> {
		return this.httpClient.post<IWalletPoint>(
			`${environment.apiUrl}${this.walletsEndPoint}withdraw/`,
			data
		);
	}

	// end of wallet end points

	// ------------------------------------------------------------------------------------

	// start of complaints end points

	getComplaintsReasons(): Observable<IAPIReasonsGetResponse> {
		// todo make the page & page size dynamic
		return this.httpClient.get<IAPIReasonsGetResponse>(
			`${environment.newApiUrl}api/v3/${this.complaintsReasonsEndPoint}?page_size=100&page=1`
		);
	}
	getAComplaintsReason(id: number): Observable<IComplaintReason> {
		return this.httpClient.get<IComplaintReason>(
			`${environment.newApiUrl}api/v3/${this.complaintsReasonsEndPoint}${id}/`
		);
	}

	createComplaintsReason(data: IAPICreateComplaintReasonPostBody) {
		return this.httpClient.post<IComplaintReason>(
			`${environment.newApiUrl}api/v3/${this.complaintsReasonsEndPoint}`,
			data
		);
	}

	updateComplaintsReason(data: IAPICreateComplaintReasonPostBody) {
		return this.httpClient.patch<IComplaintReason>(
			`${environment.newApiUrl}api/v3/${this.complaintsReasonsEndPoint}`,
			data
		);
	}

	getComplaints(parameters: IAPIGetComplaintsParams) {
		let url = `${environment.newApiUrl}api/v3/${this.complaintsEndPoint}?page_size=${parameters.page_size}&page=${parameters.page}`;

		if (parameters.restaurant) url += `&restaurant=${parameters.restaurant}`;

		if (parameters.reason) url += `&reason=${parameters.reason}`;
		if (parameters.date) url += `&date=${parameters.date}`;
		if (parameters.status) url += `&status=${parameters.status}`;
		if (parameters.complaint_to)
			url += `&complaint_to=${parameters.complaint_to}`;
		if (parameters.search) url += `&search=${parameters.search}`;
		if (parameters.resolved_at_from)
			url += `&resolved_at_from=${parameters.resolved_at_from}`;
		if (parameters.resolved_at_to)
			url += `&resolved_at_to=${parameters.resolved_at_to}`;

		if (parameters.complaint_to__in)
			url += `&complaint_to__in=${parameters.complaint_to__in}`;
		if (typeof parameters.app_id !== "undefined")
			url += `&app_id=${parameters.app_id}`;

		if (parameters.service_type)
			url += `&service_type=${parameters.service_type}`;

		return this.httpClient.get<IAPIComplaintsGetResponse>(url);
	}

	getAComplaint(id: number) {
		return this.httpClient.get<IComplaint>(
			`${environment.newApiUrl}api/v3/${this.complaintsEndPoint}${id}/`
		);
	}
	reviewComplaints(
		id: number,
		body: IAPIReviewComplaintPatchBody
	): Observable<IComplaint> {
		// const image = body.image;
		const formData = new FormData();
		// formData.append("image", image);

		let headers = new HttpHeaders();

		if (body.app_id) {
			headers = headers.append("app-id", body.app_id + "");
			delete body.app_id;
		}

		formData.append("data", JSON.stringify(body));

		return this.httpClient.patch<IComplaint>(
			`${environment.newApiUrl}api/v3/${this.complaintsEndPoint}${id}/`,
			formData,
			{
				headers: headers,
			}
		);
	}

	createComplaint(body: IAPICreateComplaintBody): Observable<IComplaint> {
		const image = body.image;
		const formData = new FormData();
		let headers = new HttpHeaders();

		if (body.app_id) {
			headers = headers.append("app-id", body.app_id + "");
			delete body.app_id;
		}

		formData.append("image", image);
		formData.append("data", JSON.stringify(body));
		return this.httpClient.post<IComplaint>(
			`${environment.newApiUrl}api/v3/${this.complaintsEndPoint}`,
			formData,
			{
				headers: headers,
			}
		);
	}

	// end of complaints end points

	// start of financial end points
	getFinancialReport(
		params: IAPIFinancialReportParams
	): Observable<IFinancialReport> {
		let url = `${environment.apiUrl}${this.financialReportEndPoint}?from_date=${params.from_date}&to_date=${params.to_date}`;
		if (params.restaurant_id) url += `&restaurant_id=${params.restaurant_id}`;
		if (typeof params.show_sub_orders === "boolean")
			url += `&show_sub_orders=${params.show_sub_orders}`;

		return this.httpClient.get<IFinancialReport>(url);
	}
	// end of financial end points

	getDashboardUserProfile(): Observable<IDashboardUserProfile> {
		return this.httpClient.get<IDashboardUserProfile>(
			`${environment.apiUrl}dashboard/me/`
		);
	}

	// Start of Orders End Points

	getOrders(
		page: IAPIGetGeneralParams,
		params?: IAPIGetOrdersParams
	): Observable<IAPIGetOrdersResponse> {
		let url = `${environment.newApiUrl}api/v3/${this.ordersEndPoint}?page_size=${page.page_size}&page=${page.page}`;

		// index to ignore page and page size

		for (const key in params) {
			const element = params[key as keyof IAPIGetOrdersParams];
			if (typeof element !== "undefined" && element !== null) {
				url += `&${key}=${params[key as keyof IAPIGetOrdersParams]}`;
			}
		}
		if (params?.captain === null) url += `&captain=${params?.captain}`;
		if (params?.app_id === null) url += `&app_id=${params?.app_id}`;
		return this.httpClient.get<IAPIGetOrdersResponse>(url);
	}

	getOrder(id: number): Observable<IOrder> {
		return this.httpClient.get<IOrder>(
			`${environment.newApiUrl}api/v3/${this.ordersEndPoint}${id}/`
		);
	}

	acceptOrder(
		id: number,
		body: IAPIAcceptRejectOrderParams
	): Observable<IOrder> {
		return this.httpClient.post<IOrder>(
			`${environment.newApiUrl}api/v3/${this.ordersEndPoint}${id}/accept/`,
			body
		);
	}

	rejectOrder(
		id: number,
		body: IAPIAcceptRejectOrderParams
	): Observable<IOrder> {
		return this.httpClient.post<IOrder>(
			`${environment.newApiUrl}api/v3/${this.ordersEndPoint}${id}/reject/`,
			body
		);
	}

	deliverOrder(id: number, body: IAPIDeliverOrderParams): Observable<IOrder> {
		return this.httpClient.post<IOrder>(
			`${environment.newApiUrl}api/v3/${this.ordersEndPoint}${id}/deliver/`,
			body
		);
	}
	cancelOrder(id: number, body: IAPICancelOrderParams): Observable<IOrder> {
		return this.httpClient.post<IOrder>(
			`${environment.newApiUrl}api/v3/${this.ordersEndPoint}${id}/cancel/`,
			body
		);
	}
	updateOrderOrdering(id: number, ordering: number): Observable<IOrder> {
		return this.httpClient.patch<IOrder>(
			`${environment.newApiUrl}api/v3/${this.ordersEndPoint}${id}/`,
			{
				ordering,
			}
		);
	}

	addPaymentAttachment(id: number, file: any, app_id: any): Observable<IOrder> {
		const formData = new FormData();
		formData.append("payment_attachment", file);
		let headers = new HttpHeaders();
		if (app_id) {
			headers = headers.append("app-id", app_id + "");
		}
		return this.httpClient.patch<IOrder>(
			`${environment.newApiUrl}api/v3/${this.ordersEndPoint}${id}/payment-attachment/`,
			formData,
			{
				headers: headers,
			}
		);
	}

	// End of Orders End Point

	// Start of *NEW* Restaurant Module End Points
	deleteProducts(
		restaurantId: number,
		productId: number
	): Observable<IProduct> {
		return this.httpClient.delete<IProduct>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/products/${productId}/`
		);
	}

	addRestaurantProduct(restaurantId: number, data: any): Observable<IProduct> {
		const formData = new FormData();
		const image = data.image;
		formData.append("image", image);
		formData.append("data", JSON.stringify(data));

		return this.httpClient.post<IProduct>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/products/`,
			formData
		);
	}

	updateRestaurantProduct(
		restaurantId: number,
		productId: number,
		data: any
	): Observable<IProduct> {
		const formData = new FormData();
		const image = data.image;

		if (image) {
			formData.append("image", image);
		} else delete data.image;

		// remove image property from data object

		formData.append("data", JSON.stringify(data));

		return this.httpClient.patch<IProduct>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/products/${productId}/`,
			formData
		);
	}

	uploadBulkProducts(
		restaurantId: number,
		categoryId: number,
		file: any
	): Observable<IAPIUploadBulkProductsResponse> {
		const formData = new FormData();
		formData.append("file", file);
		return this.httpClient.post<IAPIUploadBulkProductsResponse>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/categories/${categoryId}/import-products/`,
			formData
		);
	}
	uploadBulkNutrition(
		restaurantId: number,
		categoryId: number,
		file: any
	): Observable<IProduct[]> {
		const formData = new FormData();
		formData.append("file", file);
		return this.httpClient.post<IProduct[]>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/categories/${categoryId}/import-nutrition/`,
			formData
		);
	}
	createRestaurantCategory(
		restaurantId: number,
		data: any
	): Observable<IRestaurantCategory> {
		return this.httpClient.post<IRestaurantCategory>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/categories/`,
			data
		);
	}

	updateRestaurantCategory(
		restaurantId: number,
		categoryId: number,
		data: any
	): Observable<IRestaurantCategory> {
		return this.httpClient.patch<IRestaurantCategory>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/categories/${categoryId}/`,
			data
		);
	}

	deleteRestaurantCategory(
		restaurantId: number,
		categoryId: number
	): Observable<IRestaurantCategory> {
		return this.httpClient.delete<IRestaurantCategory>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/categories/${categoryId}/`
		);
	}

	createRestaurant(
		data: IAPIAddEditRestaurantPostBody,
		image: any | undefined,
		bannerImage: any | undefined
	): Observable<Restaurant> {
		const formData = new FormData();

		if (image) {
			formData.append("image", image);
		}
		if (bannerImage) {
			formData.append("banner", bannerImage);
		}
		formData.append("data", JSON.stringify(data));

		return this.httpClient.post<Restaurant>(
			`${environment.apiUrl}dashboard/admin/restaurants/`,
			formData
		);
	}

	updateRestaurant(
		restaurantId: number,
		data: IAPIAddEditRestaurantPostBody,
		image: any | undefined,
		bannerImage: any | undefined
	): Observable<Restaurant> {
		const formData = new FormData();
		if (image) {
			formData.append("image", image);
		}
		if (bannerImage) {
			formData.append("banner", bannerImage);
		}
		formData.append("data", JSON.stringify(data));

		return this.httpClient.patch<Restaurant>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/`,
			formData
		);
	}

	private getRestaurantBranchEndPoint(restaurantId: number) {
		return `${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/branches/`;
	}
	getListOfRestaurantBranches(
		id: number
	): Observable<IAPIGetRestaurantBranchesResponse> {
		return this.httpClient.get<IAPIGetRestaurantBranchesResponse>(
			this.getRestaurantBranchEndPoint(id)
		);
	}

	getRestaurantBranchAvailabilitiesEndPoint(
		restaurantId: number,
		branchId: number
	) {
		return `${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/branches/${branchId}/availabilities/`;
	}

	getListOfRestaurantBranchAvailabilities(
		restaurantId: number,
		branchId: number
	) {
		return this.httpClient.get<IAPIGetRestaurantBranchAvailabilityResponse>(
			this.getRestaurantBranchAvailabilitiesEndPoint(restaurantId, branchId)
		);
	}

	createRestaurantBranch(
		restaurantId: number,
		body: IAPICreateUpdateRestaurantBranchParams
	) {
		return this.httpClient.post<IRestaurantBranch>(
			this.getRestaurantBranchEndPoint(restaurantId),
			body
		);
	}

	updateRestaurantBranch(
		restaurantId: number,
		branchId: number,
		body: IAPICreateUpdateRestaurantBranchParams
	) {
		return this.httpClient.patch<IRestaurantBranch>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/branches/${branchId}/`,
			body
		);
	}

	createRestaurantBranchAvailability(
		restaurantId: number,
		branchId: number,
		body: IAPICreateUpdateRestaurantBranchAvailabilityParams
	) {
		return this.httpClient.post<IRestaurantBranchAvailability>(
			this.getRestaurantBranchAvailabilitiesEndPoint(restaurantId, branchId),
			body
		);
	}

	updateRestaurantBranchAvailability(
		restaurantId: number,
		branchId: number,
		availabilityId: number,
		body: IAPICreateUpdateRestaurantBranchAvailabilityParams
	) {
		return this.httpClient.patch<IRestaurantBranchAvailability>(
			`${this.getRestaurantBranchAvailabilitiesEndPoint(
				restaurantId,
				branchId
			)}${availabilityId}/`,
			body
		);
	}

	private getCombosEndPoint(restaurantId: number) {
		return `${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/combos/`;
	}
	getListOfRestaurantCombos(restaurantId: number) {
		return this.httpClient.get<IRestaurantCombo[]>(
			this.getCombosEndPoint(restaurantId)
		);
	}

	createRestaurantCombo(
		restaurantId: number,
		data: IAPICreateUpdateRestaurantComboParams,
		image: File
	) {
		const formData = new FormData();
		formData.append("image", image);
		formData.append("data", JSON.stringify(data));

		return this.httpClient.post<IRestaurantCombo>(
			this.getCombosEndPoint(restaurantId),
			formData
		);
	}

	updateRestaurantCombo(
		comboId: number,
		restaurantId: number,
		data: any,
		image?: File
	) {
		const formData = new FormData();

		if (image) formData.append("image", image);
		// remove image if image is null
		delete data.image;
		formData.append("data", JSON.stringify(data));

		return this.httpClient.patch<IRestaurantCombo>(
			`${this.getCombosEndPoint(restaurantId)}${comboId}/`,
			formData
		);
	}

	updateComboInventory(
		restaurantId: number,
		comboId: number,
		params: IAPIUpdateComboInventoryParams
	) {
		return this.httpClient.post<IRestaurantCombo>(
			`${this.getCombosEndPoint(restaurantId)}${comboId}/inventory/`,
			params
		);
	}

	updateProductInventory(
		restaurantId: number,
		productId: number,
		params: IAPIUpdateProductInventoryParams
	) {
		return this.httpClient.post<IProduct>(
			`${environment.apiUrl}dashboard/admin/restaurants/${restaurantId}/products/${productId}/inventory/`,
			params
		);
	}
	// End of *NEW* Restaurant Module End Points

	// Start of vouchers modules end points

	addVoucher(params: IAPIAddEditVouchersParams): Observable<iVoucherGet> {
		params.override_discount_percentage = !params.override_discount_percentage;
		return this.httpClient.post<iVoucherGet>(
			`${environment.apiUrl}dashboard/admin/vouchers/`,
			params
		);
	}
	updateVoucher(
		voucherId: number,
		params: IAPIAddEditVouchersParams
	): Observable<iVoucherGet> {
		params.override_discount_percentage = !params.override_discount_percentage;
		return this.httpClient.patch<iVoucherGet>(
			`${environment.apiUrl}dashboard/admin/vouchers/${voucherId}/`,
			params
		);
	}

	// End of vouchers modules end points

	// Start Sub Orders End Points

	getListOfZones(params: IAPIGetZonesParams): Observable<IAPIGetZonesResponse> {
		if (params.city_id === -1) delete params.city_id;
		if (!params.full) {
			params.exclude = [
				...(params.exclude ?? []),
				"city",
				"delivery_windows_options",
				"captain",
			].join(",");
		}
		const urlParams = this.constructParams(params);
		return this.httpClient.get<IAPIGetZonesResponse>(
			`${environment.apiUrl}dashboard/admin/utilities/zones/${urlParams}`
		);
	}

	createZone(data: IAPICreateUpdateZoneParams): Observable<IZone> {
		return this.httpClient.post<IZone>(
			`${environment.newApiUrl}api/v1/dashboard/admin/utilities/zones/`,
			data
		);
	}

	updateZone(
		zoneId: number,
		data: IAPICreateUpdateZoneParams
	): Observable<IZone> {
		return this.httpClient.patch<IZone>(
			`${environment.newApiUrl}api/v1/dashboard/admin/utilities/zones/${zoneId}/`,
			data
		);
	}

	deleteZone(zoneId: number, appId: number): Observable<IZone> {
		let headers = new HttpHeaders();

		headers = headers.append("app-id", appId == -2 ? "" : appId.toString());

		return this.httpClient.delete<IZone>(
			`${environment.newApiUrl}api/v1/dashboard/admin/utilities/zones/${zoneId}/`,
			{ headers }
		);
	}

	bulkPostponeSubOrders(subOrdersIds: number[], notify_customers: boolean) {
		return this.httpClient.post<ISubOrderV3[]>(
			`${environment.newApiUrl}api/v3/dashboard/admin/sub-orders/bulk-postpone/`,
			{
				sub_orders_ids: subOrdersIds,
				notify_customers: notify_customers,
			}
		);
	}

	bulkMoveSubordersLogistics(
		subOrdersIds: number[],
		skipZoneValidation: boolean,
		newProviderId: number
	) {
		return this.httpClient.post<ISubOrderV3[]>(
			`${environment.newApiUrl}api/v3/dashboard/admin/sub-orders/bulk-transfer/`,
			{
				sub_orders_ids: subOrdersIds,
				skip_zone_validation: skipZoneValidation,
				new_provider_id: newProviderId,
			}
		);
	}

	// End of Sub Orders End Points

	// Start of Invoices Module End Points

	getSubOrderInvoices(params: IAPIGetInvoicesParams) {
		let url = `${environment.apiUrl}dashboard/admin/sub-order-invoices/?`;
		for (const key in params) {
			if (params[key as keyof IAPIGetInvoicesParams]) {
				url += `${key}=${params[key as keyof IAPIGetInvoicesParams]}&`;
			}
			// if the lasr character is & remove it
		}
		if (url[url.length - 1] === "&") {
			url = url.slice(0, -1);
		}
		return this.httpClient.get<IAPIGetInvoicesResponse>(url);
	}
	getSubOrderInvoice(invoiceId: number) {
		return this.httpClient.get<IInvoice>(
			`${environment.apiUrl}dashboard/admin/sub-order-invoices/${invoiceId}/`
		);
	}

	sendBulkInvoices(params: IAPISendBulkInvoicesParams) {
		return this.httpClient.post<IAPIGetInvoicesResponse>(
			`${environment.apiUrl}dashboard/admin/sub-order-invoices/bulk-send/`,
			params
		);
	}

	// End of Invoices Module End Points

	// Start of Create Subscriptions Module End Points

	applyVoucher(params: IAPIApplySubscriptionVoucherParams, cityId: number) {
		let headers = new HttpHeaders();
		headers = headers.append("city-id", cityId.toString());
		return this.httpClient.post<IAPIApplySubscriptionVoucherResponse>(
			`${environment.newApiUrl}api/v3/dashboard/admin/orders/vouchers-apply/`,
			params,
			{
				headers: headers,
			}
		);
	}

	redeem(params: IAPIApplySubscriptionVoucherParams, cityId: number) {
		let headers = new HttpHeaders();
		headers = headers.append("city-id", cityId.toString());
		return this.httpClient.post<IAPIApplySubscriptionVoucherResponse>(
			`${environment.newApiUrl}api/v3/dashboard/admin/orders/redeem/`,
			params,
			{
				headers: headers,
			}
		);
	}

	placeOrder(params: IAPIApplySubscriptionVoucherParams, cityId: number) {
		let headers = new HttpHeaders();
		headers = headers.append("city-id", cityId.toString());
		return this.httpClient.post<IOrder>(
			`${environment.newApiUrl}api/v3/dashboard/admin/orders/`,
			params,
			{
				headers: headers,
			}
		);
	}

	getPaymentLink(orderId: number) {
		return this.httpClient.get<IAPIPaymentLinkResponse>(
			`${environment.newApiUrl}api/v3/dashboard/admin/orders/payment/${orderId}/`
		);
	}

	// MyFatoorah Payment Gateway setup
	getPaymentMethods(orderId: number) {
		return this.httpClient.get<IAPIPaymentLinkResponse>(
			`${environment.newApiUrl}api/v3/dashboard/admin/orders/myfatoorah/payment-methods/${orderId}/`
		);
	}
	getMyFatoorahPaymentLink(orderId: number | any, paymentMethodId: number) {
		return this.httpClient.get<IAPIPaymentLinkResponse>(
			`${environment.newApiUrl}api/v3/dashboard/admin/orders/myfatoorah/payment/${orderId}/?payment_method_id=${paymentMethodId}`
		);
	}

	getInvoicePeriods() {
		return this.httpClient.get<IAPIGetPaymentPeriodResponse>(
			`${environment.newApiUrl}api/v1/dashboard/admin/invoices/periods/`
		);
	}
	getMPRestaurantInvoice(
		params: IAPIGetMPRestaurantInvoiceParams
	): Observable<IAPIGetMPRestaurantInvoiceResponse> {
		let url = `${environment.newApiUrl}api/v1/dashboard/admin/invoices/mp-restaurant/?`;
		for (const key in params) {
			if (params[key as keyof IAPIGetMPRestaurantInvoiceParams]) {
				url += `${key}=${
					params[key as keyof IAPIGetMPRestaurantInvoiceParams]
				}&`;
			}
		}
		if (url[url.length - 1] === "&") {
			url = url.slice(0, -1);
		}
		return this.httpClient.get<IAPIGetMPRestaurantInvoiceResponse>(url);
	}

	generateMpRestaurantInvoice(params: IAPIGenerateMpRestaurantInvoiceParams) {
		return this.httpClient.post<MpRestaurantInvoice>(
			`${environment.newApiUrl}api/v1/dashboard/admin/invoices/generate-mp-restaurant/`,
			params
		);
	}

	createCustomerProfile(
		params: IAPICreateCustomerProfileParams
	): Observable<ICustomerProfile2> {
		return this.httpClient.post<ICustomerProfile2>(
			`${environment.newApiUrl}api/v1/dashboard/admin/customers/`,
			params
		);
	}
	updateCustomerProfile(
		customerProfileId: number,
		params: IAPIUpdateCustomerProfileParams
	): Observable<ICustomerProfile2> {
		return this.httpClient.patch<ICustomerProfile2>(
			`${environment.newApiUrl}api/v1/dashboard/admin/customers/${customerProfileId}/`,
			params
		);
	}

	updateCustomerProfileAvatar(
		customerProfileId: number,
		file: any
	): Observable<ICustomerProfile2> {
		const formData = new FormData();
		formData.append("image", file);
		return this.httpClient.post<ICustomerProfile2>(
			`${environment.newApiUrl}api/v1/dashboard/admin/customers/${customerProfileId}/avatar/`,
			formData
		);
	}
	getSubOrdersFinancial(
		params: IAPIGetSubOrdersFinancialParams
	): Observable<ISubOrderFinancial[]> {
		let url = `${environment.newApiUrl}api/v1/dashboard/financial-sub-orders/?`;
		for (const key in params) {
			if (params[key as keyof IAPIGetSubOrdersFinancialParams]) {
				url += `${key}=${
					params[key as keyof IAPIGetSubOrdersFinancialParams]
				}&`;
			}
		}
		if (url[url.length - 1] === "&") {
			url = url.slice(0, -1);
		}
		return this.httpClient.get<ISubOrderFinancial[]>(url);
	}

	getGroupedSubordersFinancial(
		params: IAPIGetGroupedSubordersFinancialParams
	): Observable<IGroupedSubOrderFinancial[]> {
		let url = `${environment.newApiUrl}api/v1/dashboard/financial-sub-orders-grouped/?`;
		for (const key in params) {
			if (params[key as keyof IAPIGetGroupedSubordersFinancialParams]) {
				url += `${key}=${
					params[key as keyof IAPIGetGroupedSubordersFinancialParams]
				}&`;
			}
		}
		if (url[url.length - 1] === "&") {
			url = url.slice(0, -1);
		}
		return this.httpClient.get<IGroupedSubOrderFinancial[]>(url);
	}

	// End of Create Subscriptions Module End Points
	// ------------------------------------------------------------------------------------

	getCities(): Observable<IAPIGetCitiesResponse> {
		return this.httpClient.get<IAPIGetCitiesResponse>(
			`${environment.apiUrl}dashboard/admin/utilities/cities/?page_size=1000&page=1`
		);
	}

	getACity(id: number): Observable<ICity> {
		return this.httpClient.get<ICity>(
			`${environment.apiUrl}dashboard/admin/utilities/cities/${id}/`
		);
	}

	createMenu(data: IAPICreateMenuParams): Observable<IMenu> {
		return this.httpClient.post<IMenu>(
			`${environment.newApiUrl}api/v1/dashboard/admin/menus/`,
			data
		);
	}

	updateMenu(id: number, data: IAPIUpdateMenuParams): Observable<IMenu> {
		return this.httpClient.patch<IMenu>(
			`${environment.newApiUrl}api/v1/dashboard/admin/menus/${id}/`,
			data
		);
	}

	updateMenuDay(
		menuId: number,
		dayId: number,
		data: IAPIUpdateMenuDayParams
	): Observable<IMenuDay> {
		return this.httpClient.patch<IMenuDay>(
			`${environment.newApiUrl}api/v1/dashboard/admin/menus/${menuId}/days/${dayId}/`,
			data
		);
	}

	// Start of Notification Center Module End Points

	getNotifications(
		page_size: number,
		page: number,
		send_date?: string,
		type?: string
	): Observable<any> {
		let filter = "";

		if (send_date) filter += `&created_at=${send_date}`;
		if (type && type !== "All") filter += `&type=${type}`;

		return this.httpClient.get<any>(
			`${environment.apiUrl}${
				this.notificationsEndPoint
			}?page_size=${page_size}&page=${page + 1}${filter}`
		);
	}

	sendNotification(body: IAPISendNotificationBody) {
		let headers = new HttpHeaders();
		body.receivers = body.receivers.replace(" ", "");
		if (body.app_id) {
			headers = headers.append("app-id", body.app_id + "");
		}

		return this.httpClient.post<IAPIGetInvoicesResponse>(
			`${environment.apiUrl}${this.notificationsEndPoint}`,
			body,
			{
				headers: headers,
			}
		);
	}

	private getDownloadProductSampleLink(restaurantId: number, category: number) {
		const url =
			environment.apiUrl +
			`dashboard/admin/restaurants/${restaurantId}/categories/${category}/export-template/`;
		return url;
	}

	downloadProductSample(restaurantId: number, category: number) {
		return this.httpClient.get(
			`${this.getDownloadProductSampleLink(restaurantId, category)}`,
			{
				responseType: "arraybuffer",
			}
		);
	}

	// End of Notification Center Module End Points
	// ------------------------------------------------------------------------------------

	// Campaigns
	createCampaign(data: any): Observable<any> {
		return this.httpClient.post<any>(
			`${environment.newApiUrl}api/v1/dashboard/admin/campaigns/`,
			data
		);
	}

	getCampaign(id: number): Observable<any> {
		return this.httpClient.get<any>(
			`${environment.newApiUrl}api/v1/dashboard/admin/campaigns/${id}/`
		);
	}
	updateCampaign(id: number, data: any): Observable<any> {
		return this.httpClient.patch<any>(
			`${environment.newApiUrl}api/v1/dashboard/admin/campaigns/${id}/`,
			data
		);
	}
	// Server DateTime End Point
	getServerDateTime() {
		return this.httpClient.get(`${environment.newApiUrl}api/v1/health/`);
	}

	// utils functions

	private _postponeSubscriptionEndPoint(subscriptionId: number): string {
		return `${environment.newApiUrl}api/v3/dashboard/admin/subscriptions/${subscriptionId}/postpone/`;
	}

	private _finishSubscriptionEndPoint(subscriptionId: number): string {
		return `${environment.newApiUrl}api/v3/dashboard/admin/subscriptions/${subscriptionId}/finish/`;
	}
	private _cancelSubscriptionEndPoint(subscriptionId: number): string {
		return `${environment.newApiUrl}api/v3/dashboard/admin/subscriptions/${subscriptionId}/cancel/`;
	}
	private _cancelBulkSubscriptionEndPoint(): string {
		return `${environment.newApiUrl}api/v3/dashboard/admin/subscriptions/bulk-cancel/`;
	}

	private _cancelSubOrderEndPoint(
		subscriptionId: number,
		subOrderId: number
	): string {
		return `${environment.newApiUrl}api/v3/dashboard/admin/subscriptions/${subscriptionId}/sub-orders/${subOrderId}/cancel/`;
	}
	private _reOpenSubOrderEndPoint(
		subscriptionId: number,
		subOrderId: number
	): string {
		return `${environment.newApiUrl}api/v3/dashboard/admin/sub-orders/${subOrderId}/re-open/`;
	}
}
