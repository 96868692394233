import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./shared/guards/auth-guard/auth.guard";
import { NotAuthGuard } from "./shared/guards/not-auth-guard/not-auth.guard";
import { AccessGuardGuard } from "./shared/guards/access-guard/access-guard.guard";

const routes: Routes = [
	// todo change the route names and support Arabic

	// {
	// 	path: "plans",
	// 	loadChildren: () => import("./plan/plan.module").then((m) => m.PlanModule),
	// },
	{
		path: "bulk-update-permission-groups",
		loadChildren: () =>
			import(
				"./organization/components/bulk-update-permissions/bulk-update-permissions.module"
			).then((m) => m.BulkUpdatePermissionsModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "onboarding",
		loadChildren: () =>
			import("./onboarding/onboarding.module").then((m) => m.OnboardingModule),
		canActivate: [AuthGuard],
	},
	{
		path: "plans-v2",
		loadChildren: () =>
			import("./plan-v2/plan-v2.module").then((m) => m.PlanV2Module),

		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "menus",
		loadChildren: () => import("./menus/menu.module").then((m) => m.MenuModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "customers",
		loadChildren: () =>
			import("./customers/customers.module").then((m) => m.CustomersModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "dashboard",
		loadChildren: () =>
			import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "organizations",
		loadChildren: () =>
			import("./organization/organizations.module").then(
				(m) => m.OrganizationsModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "analytics",
		loadChildren: () =>
			import("./quicksight-dashboards/quicksight-dashboards.module").then(
				(m) => m.QuicksightDashboardsModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "subscriptions",
		loadChildren: () =>
			import("./subscription/subscription.module").then(
				(m) => m.SubscriptionModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "sub-orders",
		loadChildren: () =>
			import("./sub-order/sub-order.module").then((m) => m.SubOrderModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "suborders-v2",
		loadChildren: () =>
			import(
				"./sub-order/components/suborders-table-v2/suborders-table-v2.module"
			).then((m) => m.SubordersTableV2Module),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "delivery",
		loadChildren: () =>
			import("./delivery/delivery.module").then((m) => m.DeliveryModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "reports",
		loadChildren: () =>
			import("./report/report.module").then((m) => m.ReportModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "vouchers",
		loadChildren: () =>
			import("./voucher/voucher.module").then((m) => m.VoucherModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "map-tool",
		loadChildren: () =>
			import("./map-tool/map-tool.module").then((m) => m.MapToolModule),
	},

	{
		path: "restaurants",
		loadChildren: () =>
			import("./restaurants/restaurants.module").then(
				(m) => m.RestaurantsModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "wallets",
		loadChildren: () =>
			import("./wallet/wallet.module").then((m) => m.WalletModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "complaints",
		loadChildren: () =>
			import("./complaint/complaint.module").then((m) => m.ComplaintModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "orders",
		loadChildren: () =>
			import("./order/order.module").then((m) => m.OrderModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "on-demand-orders",
		loadChildren: () =>
			import("./on-demand-order/on-demand-order.module").then(
				(m) => m.OnDemandOrderModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "corporates",
		loadChildren: () =>
			import("./corporates/corporates.module").then((m) => m.CorporatesModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "abandoned-carts",
		loadChildren: () =>
			import("./abandoned-cart/abandoned-cart.module").then(
				(m) => m.AbandonedCartModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "invoices",
		loadChildren: () =>
			import("./invoice/invoice.module").then((m) => m.InvoiceModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},

	{
		path: "campaigns",
		loadChildren: () =>
			import("./notification-center/notification-center.module").then(
				(m) => m.NotificationCenterModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "login",
		loadChildren: () =>
			import("./shared/components/otp-login/otp-login.module").then(
				(m) => m.OtpLoginModule
			),
		canActivate: [NotAuthGuard],
	},

	{
		path: "login/v1",
		loadChildren: () =>
			import("./shared/components/password-login/password-login.module").then(
				(m) => m.PasswordLoginModule
			),
		canActivate: [NotAuthGuard],
	},
	{
		path: "products/:id",
		loadChildren: () =>
			import(
				"./restaurants/modules/categories-page/categories-page.module"
			).then((m) => m.CategoriesPageModule),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "products",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "inventory/:id",
		loadChildren: () =>
			import(
				"./restaurants/modules/restaurant-inventory/restaurant-inventory.module"
			).then((m) => m.RestaurantInventoryModule),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "inventory",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "app-settings/:id",

		loadChildren: () =>
			import("./restaurants/modules/app-config/app-config.module").then(
				(m) => m.AppConfigModule
			),
		canActivate: [AccessGuardGuard],
	},

	{
		path: "app-settings",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "my-fatoorah/:id",
		loadChildren: () =>
			import("./my-fatoorah/my-fatoorah.module").then(
				(m) => m.MyFatoorahModule
			),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "my-fatoorah",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "sizes/:id",
		loadChildren: () =>
			import(
				"././menus/components/restaurant-sizes/restaurant-sizes.module"
			).then((m) => m.RestaurantSizesModule),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "sizes",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "branches/:id",
		loadChildren: () =>
			import("./delivery/restaurant-branches/restaurant-branches.module").then(
				(m) => m.RestaurantBranchesModule
			),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "branches",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "techrar-apps/:id",
		loadChildren: () =>
			import("./techrar-apps/techrar-apps.module").then(
				(m) => m.TechrarAppsModule
			),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "techrar-apps",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AuthGuard, AccessGuardGuard],
	},
	{
		path: "help-desk",
		loadChildren: () =>
			import("./merchant-request/merchant-request.module").then(
				(m) => m.MerchantRequestModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: "otp",
		loadChildren: () => import("./otp/otp.module").then((m) => m.OtpModule),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "migrate/:id",
		loadChildren: () => import("./migrate/migrate.module").then((m) => m.MigrateModule),
		canActivate: [AccessGuardGuard],
	},
	{
		path: "migrate",
		loadChildren: () =>
			import(
				"./shared/reusable-components/modules/restaurant-navigator/restaurant-navigator.module"
			).then((m) => m.RestaurantNavigatorModule),
		canActivate: [AccessGuardGuard],
	},
	{ path: "", redirectTo: "/login", pathMatch: "full" },
	{
		path: "under-maintenance",
		loadChildren: () =>
			import("./shared/reusable-components/modules/under-maintenance-page/under-maintenance-page.module").then(
				(m) => m.UnderMaintenancePageModule
			),
		canActivate: [AuthGuard],
	},
	{
		path: "**",
		loadChildren: () =>
			import("./shared/components/not-found/not-found.module").then(
				(m) => m.NotFoundModule
			),
		canActivate: [AuthGuard],
	},
];
@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
