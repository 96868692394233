import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-boolean-badge",
	templateUrl: "./boolean-badge.component.html",
	styleUrls: ["./boolean-badge.component.scss"],
})
export class BooleanBadgeComponent implements OnInit {
	@Input() value: boolean = false;
	@Input() textValue: string = "";
	@Input() inactiveTextValue?: string;

	@Input() width = "auto";
	@Input() height = "auto";

	@Input() toolTipMessage: string = "";

	constructor() {}

	ngOnInit(): void {}

	get textWhenInactive() {
		return this.inactiveTextValue ? this.inactiveTextValue : this.textValue;
	}
	get shouldHaveTooltip(): boolean {
		return this.toolTipMessage.length > 0;
	}

	get widthWithUnits() {
		return this.width === "auto" ? this.width : this.width + "px";
	}
	get heightWithUnits() {
		return this.height === "auto" ? this.height : this.height + "px";
	}
}
