import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { httpInterceptorProviders } from "./shared/interceptors/httpProviders";

import { AppComponent } from "./shared/components/app-component/app.component";
import { environment } from "src/environments/environment";
import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { provideFirestore, getFirestore } from "@angular/fire/firestore";
import { HttpClientModule } from "@angular/common/http";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SidebarModule } from "./shared/components/sidebar/sidebar.module";
import { MyGoogleMapModule } from "./shared/reusable-components/modules2/my-google-maps.module";
import {
	NgxGoogleAnalyticsModule,
	NgxGoogleAnalyticsRouterModule,
} from "ngx-google-analytics";

import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		// provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		// provideFirestore(() => getFirestore()),
		AppRoutingModule,
		BrowserModule,
		BrowserAnimationsModule,
		SidebarModule,
		// the following is imported for the app service
		HttpClientModule,
		MatSnackBarModule,
		MatDialogModule,

		// todo remove this once you solve the problem of the map not showing in dialogs
		MyGoogleMapModule,
		NgxGoogleAnalyticsModule.forRoot("G-HG6QWCJFLT"),
		provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
		provideFirestore(() => getFirestore()),
		LottieModule.forRoot({ player: playerFactory }),
	],
	providers: [httpInterceptorProviders],
	bootstrap: [AppComponent],
})
export class AppModule {}
