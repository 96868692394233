import { Injectable } from "@angular/core";
import { IRestaurantCategory } from "../models/classes.model";

@Injectable({
	providedIn: "root",
})
export class CasheService {
	private cachedRestaurantCategories!: IRestaurantCategory[];
	setCachedRestaurantCategories(categories: IRestaurantCategory[]) {
		this.cachedRestaurantCategories = categories;
	}
	getCachedRestaurantCategories(): IRestaurantCategory[] {
		return this.cachedRestaurantCategories;
	}

	constructor() {}
}
