import { Pipe, PipeTransform } from "@angular/core";
import { LanguageService } from "../services/language-service/language.service";

@Pipe({
	name: "translate",
})
export class TranslatePipe implements PipeTransform {
	constructor(private languageService: LanguageService) {}

	transform(term: string): string {
		return this.languageService.translate(term);
	}
}
