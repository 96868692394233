<a
	mat-mini-fab
	color="accent"
	aria-label="Guide line button"
	[matTooltip]="'view-the-guideline' | translate"
	[href]="getAppLink()"
	target="_blank"
>
	<mat-icon>book</mat-icon>
</a>
