<div class="my-dialog-form-container">
	<h3>{{ data.message | translate }}</h3>
	<mat-icon (click)="cancel()" class="close-button">close</mat-icon>

	<div class="boolean-value">
		<mat-slide-toggle
			[checked]="booleanValue"
			(change)="booleanValue = !booleanValue"
		>
			{{ data.booleanValueTitle | translate }}
		</mat-slide-toggle>
	</div>
	<div class="actions">
		<button class="button" (click)="cancel()" mat-raised-button color="warn">
			{{ cancelMessage | translate }}
		</button>
		<button
			class="button confirm-button"
			(click)="confirm()"
			mat-raised-button
			color="accent"
		>
			{{ confirmMessage | translate }}
		</button>
	</div>
</div>
