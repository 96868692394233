<div class="pop-up-container">
	<div class="pop-up__header">
		<div class="pop-up__header__title">
			<ng-container *ngFor="let text of data.headerTexts">
				{{ text | translate }}
			</ng-container>
			{{ data.modelName | translate }}
		</div>
	</div>
	<div class="pop-up__content">
		<div class="label">
			<ng-container *ngFor="let text of data.headerTexts">
				{{ text | translate }}
			</ng-container>
			{{ data.modelName | translate }}
		</div>
		<app-restaurants-filter
			[allowMultipleSelect]="data.supportMultiSelect"
			(multipleSelectChange)="onMultipleSelectChange($event)"
			(singleSelectChange)="onSingleSelectChange($event)"
		></app-restaurants-filter>
		<div class="pop-up__footer-actions">
			<button (click)="closeForm()" mat-button>
				{{ "cancel" | translate }}
			</button>
			<button
				(click)="confirm()"
				color="accent"
				mat-raised-button
				class="button"
			>
				{{ "confirm" | translate }}
			</button>
		</div>
	</div>
</div>
