import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface IConfirmDialogWithBooleanData {
	message: string;
	confirmMessage: string;
	cancelMessage: string;
	booleanValueTitle: string;
}

export interface IConfirmDialogWithBooleanOutputData {
	didConfirm: boolean;
	booleanValue: boolean;
}
@Component({
	selector: "app-confirm-action-with-boolean",
	templateUrl: "./confirm-action-with-boolean.component.html",
	styleUrls: ["./confirm-action-with-boolean.component.scss"],
})
export class ConfirmActionWithBooleanComponent implements OnInit {
	booleanValue = false;
	constructor(
		public dialogRef: MatDialogRef<IConfirmDialogWithBooleanData>,
		@Inject(MAT_DIALOG_DATA) public data: IConfirmDialogWithBooleanData
	) {}
	ngOnInit(): void {}
	get confirmMessage() {
		return this.data.confirmMessage || "confirm";
	}
	get cancelMessage() {
		return this.data.cancelMessage || "cancel";
	}

	confirm() {
		this.dialogRef.close({
			didConfirm: true,
			booleanValue: this.booleanValue,
		});
	}
	cancel() {
		this.dialogRef.close({
			didConfirm: false,
			booleanValue: this.booleanValue,
		});
	}
}
