import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppService } from "src/app/shared/services/app-service/app-service";

@Component({
	selector: "app-search-bar",
	templateUrl: "./search-bar.component.html",
	styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit {
	appLanguage = this.appService.getCurrentLanguage();

	@Input() searchText: string = "";
	@Input() placeHolder: string = this.appLanguage === "en" ? "Search" : "بحث";
	@Input() showSearchIcon: boolean = false;
	@Input() minSearchTextLength: number = 0;
	@Output() searchEvent = new EventEmitter<string>();

	showSearchTextLimit: boolean = false;

	constructor(public appService: AppService) {}

	ngOnInit(): void {}
	keyUp(event: KeyboardEvent) {
		if (this.searchText.length > this.minSearchTextLength) {
			this.showSearchTextLimit = false;
		}
	}
	fireChangeEvent() {
		if (
			this.minSearchTextLength > 0 &&
			this.searchText.length < this.minSearchTextLength
		) {
			this.showSearchTextLimit = true;
		} else {
			this.showSearchTextLimit = false;
		}
		this.searchEvent.emit(this.searchText);
	}

	clearText() {
		this.searchText = "";
		this.fireChangeEvent();
		this.showSearchTextLimit = false;
	}
}
