import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivate,
	RouterStateSnapshot,
	UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth-service/auth.service";

@Injectable({
	providedIn: "root",
})
export class AuthGuard implements CanActivate {
	constructor(private authService: AuthService) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	):
		| Observable<boolean | UrlTree>
		| Promise<boolean | UrlTree>
		| boolean
		| UrlTree {
		const isLoggedIn = this.authService.isLoggedIn();
		const isRefreshTokenValid = this.authService.isRefreshTokenValid();

		if (!isLoggedIn && !isRefreshTokenValid) this.authService.navigateToLogin();
		return isLoggedIn;
	}
}
