import { Pipe, PipeTransform } from "@angular/core";
import { AppService } from "src/app/shared/services/app-service/app-service";

@Pipe({
	name: "timeFormatConverter",
})
export class TimeFormatConverterPipe implements PipeTransform {
	constructor(public appService: AppService) {}
	transform(value: any, ...args: unknown[]): string {
		if (args.includes("am-pm")) {
			if (this.appService.getCurrentLanguage() === "ar") {
				if (value.toString().includes("AM")) {
					return value.toString().replace("AM", "ص");
				} else {
					return value.toString().replace("PM", "م");
				}
			}
		}
		return value;
	}
}
