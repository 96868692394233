<mat-form-field appearance="outline" [class.full-width]="fullWidth">
	<mat-label>{{ "delivery-window" | translate }}</mat-label>
	<mat-select
		[disabled]="disabled"
		(selectionChange)="outputSelectEvent()"
		[multiple]="supportMultiSelect"
		[(value)]="selectedValue"
	>
		<mat-option *ngIf="includeAllOption" [value]="-1">{{
			"all" | translate
		}}</mat-option>
		<mat-option *ngIf="includeNullOption" [value]="-2">{{
			"yumealz" | translate
		}}</mat-option>
		<mat-option
			*ngFor="let deliveryWindow of filteredDeliveryWindowsOptions"
			[value]="deliveryWindow.id"
			>{{ deliveryWindow.from_time | timeFormatConverter : "am-pm" }} -
			{{ deliveryWindow.to_time | timeFormatConverter : "am-pm" }}</mat-option
		>
	</mat-select>
	<mat-spinner
		diameter="20"
		color="accent"
		*ngIf="isLoading"
		matSuffix
		class="loading"
	></mat-spinner>
</mat-form-field>
