<div class="pop-up-container">
	<div class="pop-up__header">
		<div class="pop-up__header__title">{{ data.title | translate }}</div>
	</div>

	<div class="pop-up__content">
		<span class="message"> {{ data.message | translate }}</span>
		<div
			class="pop-up__footer-actions"
			[class.space-between]="data.additionalConfirmationButtonMessage"
		>
			<button (click)="cancel()" mat-button>
				{{ cancelMessage | translate }}
			</button>
			<div class="confirm-buttons-container">
				<button
					class="button confirm-button"
					(click)="confirm(true)"
					mat-raised-button
					color="accent"
					*ngIf="data.additionalConfirmationButtonMessage"
				>
					{{ data.additionalConfirmationButtonMessage | translate }}
				</button>
				<button
					class="button confirm-button"
					(click)="confirm()"
					mat-raised-button
					color="accent"
				>
					{{ confirmMessage | translate }}
				</button>
			</div>
		</div>
	</div>
</div>
