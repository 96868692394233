import { MatSnackBar } from "@angular/material/snack-bar";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { Restaurant, User } from "src/app/shared/models/classes.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { catchError, map } from "rxjs/operators";
import { JwtService } from "../jwt-service/jwt.service";
import {
	Lang,
	LanguageService,
} from "src/app/translate/translation/services/language-service/language.service";

export interface IOtpResponse {
	message: string;
	waiting_seconds: number;
	_timestamp: string;
}

export enum IUserAccessType {
	MP_YUMEALZ = "mp_yumealz",
	MP_PROVIDER = "mp_provider",
	SaaS = "saas",
	Both = "both",
}

@Injectable({
	providedIn: "root",
})
export class AuthService {
	// todo fix this
	private userSubject: BehaviorSubject<User | null>;
	public user$: Observable<User | null>;

	// todo make the value of home component based on the provided permission and remove the rerouting logic from the access guard
	readonly homeComponent = "dashboard";

	private otpEndPoint = environment.newApiUrl + "api/v2/auth/request-otp/";
	private accessV2EndPoint =
		environment.newApiUrl + "api/v2/auth/dashboard-access/";
	private verifyOtpEndPoint = environment.newApiUrl + "api/v2/auth/otp-check/";
	private changePasswordEndPoint =
		environment.newApiUrl + "api/v2/auth/change-password/";
	private accesTokenEndPoint = environment.apiUrl + "auth/dashboard-access/";
	private refreshTokenEndPoint =
		environment.newApiUrl + "api/v2/auth/dashboard-refresh/";
	appLanguage = this.languageService.getCurrentLang();
	constructor(
		private router: Router,
		private http: HttpClient,
		private snackBar: MatSnackBar,
		private jwtService: JwtService,
		private languageService: LanguageService
	) {
		//todo: change this to use token
		this.userSubject = new BehaviorSubject<User | null>(null);
		this.user$ = this.userSubject.asObservable();
	}

	public get userValue(): User {
		if (this.userSubject.value) {
			return this.userSubject.value;
		} else {
			// todo fix this
			return {
				username: "dummy user",
			};
		}
	}
	logIn(loginInfo: {
		username?: string;
		email?: string;
		password: string;
	}): Observable<User> {
		return this.http
			.post<User>(`${this.accessV2EndPoint}`, {
				...loginInfo,
			})
			.pipe(
				map((response) => {
					const user = this.jwtService.storeTokens(response);
					this.userSubject.next(user);
					const loggedInMessage =
						this.appLanguage == Lang.english
							? "Logged In Successfully"
							: "تم تسجيل الدخول بنجاح";
					this.alert(loggedInMessage);
					this.navigateToHome();
					location.reload();
					return user;
				}),
				catchError((e) => {
					console.log(e);
					return throwError(() => e);
				})
			);
	}
	requestOtp(otpRequestInfo: {
		username?: string;
		mobile_number?: string;
		email?: string;
		action?: string;
	}): Observable<IOtpResponse> {
		return this.http.post<IOtpResponse>(`${this.otpEndPoint}`, {
			...otpRequestInfo,
			client: "dashboard",
		});
	}
	loginWithOtp(loginInfo: { otp: string; username?: string; email?: string }) {
		return this.http
			.post<User>(`${this.accessV2EndPoint}`, {
				...loginInfo,
			})
			.pipe(
				map((response) => {
					const user = this.jwtService.storeTokens(response);
					this.userSubject.next(user);
					const loggedInMessage =
						this.appLanguage == Lang.english
							? "Logged In Successfully"
							: "تم تسجيل الدخول بنجاح";
					this.alert(loggedInMessage);
					this.navigateToHome();
					location.reload();
					return user;
				}),
				catchError((e) => {
					console.log(e);
					return throwError(() => e);
				})
			);
	}

	verifyOtp(otpVerifyInfo: {
		otp: string;
		mobile_number?: string;
		email?: string;
	}) {
		return this.http.post<any>(`${this.verifyOtpEndPoint}`, {
			...otpVerifyInfo,
		});
	}

	changePassword(changePasswordInfo: {
		new_password: string;
		email?: string;
		mobile_number?: string;
	}) {
		return this.http.post<any>(`${this.changePasswordEndPoint}`, {
			...changePasswordInfo,
		});
	}

	refreshToken() {
		const refreshToken = this.jwtService.getRefreshToken();
		if (!refreshToken) {
			this.logOut();
		}

		return this.http.post(`${this.refreshTokenEndPoint}`, {
			refresh: refreshToken,
		});
	}
	logOut() {
		//todo : change this to delete refresh token from auth server
		this.jwtService.clearTokens();
		this.userSubject.next(null);
		const loggedOutMessage =
			this.appLanguage == Lang.english
				? "Logged Out Successfully"
				: "تم تسجيل الخروج بنجاح";
		this.alert(loggedOutMessage);
		this.navigateToLogin();
	}

	changeOrganization(newOrgId: number) {
		const refreshToken = this.jwtService.getRefreshToken();
		if (!refreshToken) {
			this.logOut();
		}

		this.http
			.post(`${environment.newApiUrl}api/v1/dashboard/change-org/`, {
				refresh: refreshToken,
				new_org_id: newOrgId,
			})
			.subscribe((response) => {
				this.removeOldOrgLocalStorageItem();
				const user = this.jwtService.storeTokens(response);
				this.userSubject.next(user);
				location.reload();
			});
	}

	removeOldOrgLocalStorageItem() {}
	getLoggedInUserId(): number {
		const decodedToken = this.jwtService.getDecodedAccessToken();
		return decodedToken.user_id;
	}
	getUserAccessType(): IUserAccessType {
		const decodedToken = this.jwtService.getDecodedAccessToken();
		return decodedToken?.access_type;
	}
	isLoggedIn(): boolean {
		return this.jwtService.isAccessTokenValid();
	}
	isRefreshTokenValid(): boolean {
		return this.jwtService.isRefreshTokenValid();
	}
	isYumealzUser(): boolean {
		return this.getUserAccessType() === IUserAccessType.MP_YUMEALZ;
	}
	hasSaaS(): boolean {
		return (
			this.getUserAccessType() === IUserAccessType.SaaS ||
			this.getUserAccessType() === IUserAccessType.Both
		);
	}
	getRestaurantIds(): number[] {
		return (this.jwtService.getDecodedAccessToken().app_ids as string)
			.split(",")
			.map((value) => Number(value));
	}

	getOrgId(): number {
		return this.jwtService.getDecodedAccessToken().org_id;
	}

	// redirect functions
	navigateToHome() {
		this.router.navigate([this.homeComponent]);
	}

	navigateToLogin() {
		this.router.navigate(["login"]);
	}

	// other functions
	alert(message: string) {
		this.snackBar.open(message, "Ok", {
			horizontalPosition: "center",
			verticalPosition: "bottom",
			duration: 300,
		});
	}
}
